import React from 'react';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';

import Roles   from '../../Roles/Roles';

const Hotline = () => {

  return (
    <>
      <Spacer />
      <Heading subtitle='Mitgliederbereich'>Hotline</Heading>
      <p>Alle praktizierenden FVDH-Mitglieder können sich in dringenden Angelegenheiten direkt an den Vorstand wenden.</p>
      <Roles role_name='Vorstand' />
    </>
  )
};

export default Hotline;
