import React, { useContext }           from 'react';
import { navigate }                    from 'gatsby';
import { useQueryParams, StringParam } from 'use-query-params';
import { useFormState }                from 'react-use-form-state';

import Button         from '@interness/web-core/src/components/elements/Button/Button';
import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import { ApiContext } from '@interness/users-addon/src/context/Api';
import toastHandler   from '@interness/users-addon/src/helpers/toastHandler';

import { Form, Input } from './Login/styles';

const ResetPasswordPage = () => {
  const { handlePasswordReset } = useContext(ApiContext);
  const [query] = useQueryParams({
    token: StringParam,
  });
  const [formState, { password, label }] = useFormState({}, {
    withIds: true,
    validateOnBlur: true,
  });

  const { token } = query;

  const onSubmit = async e => {
    e.preventDefault();
    if (token) {
      const message = await handlePasswordReset(formState.values.password, token);
      if (message.error) {
        navigate('/mitgliederbereich?resetPassword=1');
        toastHandler(null, null, null, message.error.code);
      } else {
        navigate('/mitgliederbereich');
        toastHandler('success', 'Sie können sich nun anmelden.', 'Password erfolgreich zurückgesetzt')
      }
    } else {
      toastHandler('error', 'Bitte öffnen Sie den Link über die E-Mail', 'Kein Token vorhanden')
    }
  };

  return (
    <div>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Passwort zurücksetzen</Heading>
      <Form name='reset-password' onSubmit={onSubmit}>
        <Input {...label('password')}>
          <input {...password('password')} placeholder='&nbsp;' required/>
          <span className='label'>Neues Password</span>
          <span className='focus-bg'/>
        </Input>
        <Spacer height={20}/>
        <Button primary type='submit'
                disabled={formState.values.password === ''}>Passwort zurücksetzen</Button>
      </Form>
    </div>
  )
};

export default ResetPasswordPage;