import styled from '@emotion/styled';

import Card from '../Card/Card';

export const Profile = styled(Card)`
  max-width: ${props => props.maxWidth ? props.maxWidth : 'auto'};
  text-align: ${props => props.type === 'card' ? 'center' : 'left'};
  margin: 0 auto 80px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => props.type === 'card' ? 'column' : 'row'};
  > div:first-child {
    width: ${props => props.type === 'card' ? '100%' : '40%'};
  }
  > div:nth-child(2) {
    width: ${props => props.type === 'card' ? '100%' : '60%'};
  }
  .int-member-title {
    text-align: left;
    display: block;
  }
  .int-member-job {
    text-align: ${props => props.type === 'card' ? 'right' : 'left'};
    display: block;
    color: gray;
  }
  h4 {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
  ul {
    margin: 30px auto 0 auto;
    list-style-type: none;
    li {
      text-align: right;
      margin: 5px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;
      padding: 8px 10px;
      svg {
        flex-shrink: 0;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
`;