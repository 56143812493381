import React, { useState, useContext } from 'react';
import PropTypes                       from 'prop-types';
import ReactPaginate                   from 'react-paginate';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import { ApiContext }                from '@interness/users-addon/src/context/Api';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import Article from '../Article/Article';

import * as S from './styles';
import Error  from '../Error/Error';

const Articles = ({ category, limit, sort }) => {

  const { isLoggedIn } = useContext(ApiContext);
  const [page, setPage] = useState(0);

  const reqParams = {
    sort,
    limit,
    meta: '*',
    fields: '*.*',
    page: page + 1
  };
  if (category) {
    reqParams['filter[category.slug]'] = category;
  }

  const { data: articles, loading, meta, error } = useClient('article', reqParams, [isLoggedIn, page], true);

  const onSelectPage = (e) => {
    setPage(e.selected)
  };

  return (
    <div>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {error && <Error message='Beiträge konnten nicht geladen werden' />}
      {articles && articles.map(article => <Article path={article.slug} key={article.id} data={article}
                                                    isLoggedIn={isLoggedIn}/>)}
      {articles && articles.length === 0 && <div style={{ textAlign: 'center' }}><p>Keine Beiträge gefunden</p></div>}
      {meta && meta.page_count > 1 &&
      <S.Pagination>
        <ReactPaginate
          previousLabel={'Vorherige'}
          nextLabel={'Nächste'}
          breakLabel={'...'}
          pageCount={meta.page_count}
          onPageChange={e => onSelectPage(e)}
          forcePage={page}
        />
      </S.Pagination>
      }
    </div>
  )
};

Articles.propTypes = {
  category: PropTypes.string,
  limit: PropTypes.number,
  sort: PropTypes.string,
};

Articles.defaultProps = {
  limit: 10,
  sort: '-published_date',
};

export default Articles;
