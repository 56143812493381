import styled  from '@emotion/styled';
import { css } from '@emotion/react';


export const Bar = styled.div`
  min-height: 60px;
  width: 100%;
  color: #fff;
  padding: 10px 50px;
  background-color: ${props => props.adt ? 'green' : props.theme.brand_color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  h3 {
    margin-bottom: 0;
    padding: 10px 0;
  }

  .int-expander-toggler {
    border: none;
    outline: none;
    background: unset;
    color: #fff;

    svg {
      fill: #fff;
      vertical-align: text-top;
    }
  }
`;

export const Form = styled.form`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;

  > p {
    margin: 10px 10px 10px auto;
  }

  > * {
    margin: 10px;
  }

  input {
    padding: 0 10px;
    width: 200px;
  }

  select {
    height: 31px;
    //margin: 10px;
    background-color: #fff;
    width: 200px;
  }

  button {
    max-height: 31px;
    padding: 5px 10px;
  }
`;

export const dialogOverlay = css`
  &[data-reach-dialog-overlay] {
    z-index: 1102;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
  }
`;

export const dialogContent = css`
  &[data-reach-dialog-content] {
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1103;
    margin: auto;
    background-color: ${props => props.theme.base_color};
    width: 90%;
    max-width: 960px;
    color: #333;
    padding: 0;
    overflow: hidden;
  }
`;

export const CloseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
`;

export const DialogHeader = styled.header`
  background-color: ${props => props.theme.brand_color};
  color: #fff;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  .int-btn-close {
    > svg {
      fill: #fff;
      height: 25px;
      width: 25px;
    }
  }

  h3 {
    margin: 0;
  }
`;

export const Inner = styled.div`
  padding: 30px;
  overflow-y: scroll;
  max-height: 80vh;

  > ul {
    margin: 0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  > div {
    flex-basis: 50%;
    margin: auto;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 1.2rem;
  }

  .int-therapy-details {
    padding: 20px 10px;
  }
`;
