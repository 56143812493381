import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Verbandsaufgaben`}</h1>
      <h2 {...{
        "id": "aufgaben-und-serviceleistungen-des-fvdh-ev-fur-mitglieder"
      }}>{`Aufgaben und Serviceleistungen des FVDH e.V. für Mitglieder`}</h2>
      <ul>
        <li parentName="ul">{`kostenlose Fachzeitschrift AKOM`}</li>
        <li parentName="ul">{`Beratung der Heilpraktiker in Fragen der Praxisgründung und Praxisführung`}</li>
        <li parentName="ul">{`Unterstützung, Förderung und Beratung in berufsständischen und fachlichen Fragen`}</li>
        <li parentName="ul">{`Interessenvertretung gegenüber Behörden und den gesetzgebenden Körperschaften sowie in der Öffentlichkeit`}</li>
        <li parentName="ul">{`Beratung und Unterstützung bei berufsbezogenen gerichtlichen Auseinandersetzungen`}</li>
        <li parentName="ul">{`Berufliche Fortbildung in den Landesverbänden und auf Bundesebene`}</li>
        <li parentName="ul">{`Zertifikate für Fachaus- und Weiterbildungen`}</li>
        <li parentName="ul">{`Mitgliedsausweis und Mitgliedsnummer`}</li>
        <li parentName="ul">{`umfassendes Nachschlagewerk “Heilpraktiker Gesetzeskunde”`}</li>
      </ul>
      <h2 {...{
        "id": "praktizierende-heilpraktiker-erhalten"
      }}>{`Praktizierende Heilpraktiker erhalten:`}</h2>
      <ul>
        <li parentName="ul">{`Mitgliedsstempel, Zusatzschild für die Praxis, Gebührenverzeichnis, `}<a parentName="li" {...{
            "href": "/service/berufsordnung"
          }}>{`Berufsordnung`}</a></li>
        <li parentName="ul">{`Autoplakette : Heilpraktiker im Dienst`}</li>
        <li parentName="ul">{`Eintrag in unsere verbandseigene FVDH-Online-Datenbank, mit Suchfunktion für Patienten`}</li>
        <li parentName="ul">{`Hilfestellung bei der Erstellung Ihrer eigenen Praxis Homepage.`}</li>
        <li parentName="ul">{`Gruppenversicherungen (Berufs-Haftpflicht, Rentenversicherung, Krankentagegeld)`}</li>
        <li parentName="ul">{`Adressenvermittlung an Patienten`}</li>
      </ul>
      <h2 {...{
        "id": "berufs--und-medizinalpolitische-aufgaben-des-verbandes"
      }}>{`Berufs- und medizinalpolitische Aufgaben des Verbandes:`}</h2>
      <ul>
        <li parentName="ul">{`Pflege von Kontakten zu Regierungen, Behörden, Gesundheitsämtern`}</li>
        <li parentName="ul">{`Hilfestellung bei den Überprüfungen nach dem Heilpraktikergesetz`}</li>
        <li parentName="ul">{`Der FVDH e.V. ist Mitglied im Dachverband Deutscher Heilpraktikerverbände (DDH). Hierbei handelt es sich um fünf große Heilpraktikerverbände in Deutschland (BDHN, FDH, FVDH, UDH, VDH).`}</li>
        <li parentName="ul">{`Zusammenarbeit mit Verbänden des Gesundheitswesens in anderen europäischen Ländern`}</li>
      </ul>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      