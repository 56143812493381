import React      from 'react';
import { Router } from '@reach/router';

import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import PrivateRoute from '@interness/users-addon/src/components/PrivateRoute';

import Profile                 from '../components/internal/pages/Profile';
import Login                   from '../components/internal/pages/Login/Login';
import MembersHome             from '../components/internal/pages/MembersHome';
import NotFound                from '../components/NotFound';
import ResetPassword           from '../components/internal/pages/ResetPassword';
import Register                from '../components/internal/pages/Register';
import Hotline                 from '../components/internal/pages/Hotline';
import Rechtsberatung          from '../components/internal/pages/Rechtsberatung';
import Produkte                from '../components/internal/pages/Produkte';
import Presse                  from '../components/internal/pages/Presse';
import GebuehrenverzeichnisADT from '../components/internal/pages/GebuehrenverzeichnisADT';
import BehandlungsvertragADT   from '../components/internal/pages/adt/Behandlungsvertrag';
import BerufsordnungADT        from '../components/internal/pages/adt/Berufsordnung';

function MitgliederPage() {
  return (
    <>
      <Wrapper>
        <Router>
          <Login path="/mitgliederbereich"/>
          <Register path="/mitgliederbereich/register"/>
          <ResetPassword path="/mitgliederbereich/reset-password" redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/home" heading="Mitgliederinformationen" component={MembersHome}
                        redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/home-adt" category="adt-news" heading="Mitgliederinformationen (ADT)"
                        component={MembersHome} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/arzneimittelkommision" category="arzneimittelkommission"
                        heading="Arzneimittelkommission" component={MembersHome} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/profil" component={Profile} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/hotline" component={Hotline} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/rechtsberatung" component={Rechtsberatung}
                        redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/produkte" component={Produkte} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/presse" component={Presse} redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/gebuehrenverzeichnis-adt" component={GebuehrenverzeichnisADT}
                        redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/behandlungsvertrag-adt" component={BehandlungsvertragADT}
                        redirect={'/mitgliederbereich'}/>
          <PrivateRoute path="/mitgliederbereich/berufsordnung-adt" component={BerufsordnungADT}
                        redirect={'/mitgliederbereich'}/>
          <NotFound default/>
        </Router>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default MitgliederPage;
