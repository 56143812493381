import React                     from 'react';
import PropTypes                 from 'prop-types';
import styled                    from '@emotion/styled';
import { FaExclamationTriangle } from 'react-icons/fa';

const Bar = styled.div`
  background-color: #e74c3c;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  max-width: 500px;
  margin: auto;
  border-radius: 3px;
  > * {
    display: inline-block;
  }
  p {
    margin-bottom: 0;
  }
  svg {
    fill: #fff;
    vertical-align: text-top;
    margin-right: 10px;
  }
`;

const Error = ({ message }) => {
  return (
    <Bar>
      <FaExclamationTriangle/>
      <p>{message}</p>
    </Bar>
  )
};

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: 'Fehler beim Laden'
};

export default Error;