import React, { useContext, useEffect, useState } from 'react';
import { navigate }                               from 'gatsby';
import { useFormState }                           from 'react-use-form-state';
import { BooleanParam, useQueryParams }           from 'use-query-params';
import { Flex, Box }                              from '@chakra-ui/react';

import Button            from '@interness/web-core/src/components/elements/Button/Button';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner           from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import { ApiContext }    from '@interness/users-addon/src/context/Api';
import { GlobalContext } from '@interness/users-addon/src/context/Global';
import toastHandler      from '@interness/users-addon/src/helpers/toastHandler';


import * as S from './styles';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const { handleLogin, isLoggedIn, handlePasswordResetRequest } = useContext(ApiContext);
  const { pathBeforeRedirect, setPathBeforeRedirect } = useContext(GlobalContext);
  const [formState, { email, password, label, checkbox }] = useFormState({}, {
    withIds: true,
    validateOnBlur: true,
  });

  const [query] = useQueryParams({
    resetPassword: BooleanParam,
  });

  const { resetPassword } = query;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(pathBeforeRedirect ? pathBeforeRedirect : '/mitgliederbereich/home');
      setPathBeforeRedirect(null);
    }
    if (resetPassword) {
      setPasswordView(true);
    }
    //eslint-disable-next-line
  }, [isLoggedIn, resetPassword]);

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (passwordView) {
      const message = await handlePasswordResetRequest(formState.values.email);
      if (message.error) {
        setLoading(false);
        toastHandler(null, null, null, message.error.code);
      } else {
        setLoading(false);
        setPasswordView(false);
        toastHandler('success', `Bitte kontrollieren Sie Ihre E-Mail (${formState.values.email}).`, 'Passwort Zurückgesetzt');
      }
    } else {
      try {
        const message = await handleLogin(formState.values);
        if (message === 'success') {
          toastHandler('success', 'Erfolgreich angemeldet.', 'Angemeldet');
          setLoading(false);
          navigate(pathBeforeRedirect ? pathBeforeRedirect : '/mitgliederbereich/home');
          setPathBeforeRedirect(null);
        } else {
          console.log(message);
          setLoading(false);
          toastHandler('error', null, null, message)
        }
      } catch (e) {
        setLoading(false);
        toastHandler('error', null, e)
      }
    }
  };

  const toggleView = () => {
    setPasswordView(!passwordView);
  };

  let buttonDisabled;

  if (passwordView) {
    buttonDisabled = formState.values.email === '' || loading;
  } else {
    buttonDisabled = formState.values.email === '' || formState.values.password === '' || loading;
  }

  return (
    <div>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Anmelden</Heading>
      <Flex flexWrap='wrap'>
        <Box width={['100%', '30%', '30%', '30%']}>
          <h2 style={{ textAlign: 'center' }}>Hinweis</h2>
          <p>Willkommen auf unserer neuen Verbandshomepage.</p>
          <p>Aus Sicherheitsgründen haben wir Ihre alten Zugangsdaten deaktiviert. Bitte registrieren Sie sich mit Ihren
            Mitgliederdaten neu.</p>
          <Button as={Link} to={'/mitgliederbereich/register'}>Registrieren</Button>
          <Spacer height={20}/>
        </Box>
        <Box width={['100%', '70%', '70%', '70%']}>
          <h2 style={{ textAlign: 'center' }}>{passwordView ? 'Passwort vergessen' : 'Anmelden'}</h2>
          <S.Form
            name='login'
            onSubmit={onSubmit}>
            <S.Input {...label('email')}>
              <input {...email('email')} placeholder='&nbsp;' required/>
              <span className='label'>E-Mail</span>
              <span className='focus-bg'/>
            </S.Input>
            <Spacer height={20}/>
            {!passwordView && <>
              <S.Input {...label('password')}>
                <input {...password('password')} placeholder='&nbsp;' required/>
                <span className='label'>Password</span>
                <span className='focus-bg'/>
              </S.Input>
              <Spacer height={20}/>
              <label {...label('persist')}>
                <input {...checkbox('persist')} style={{ marginRight: '8px', verticalAlign: 'text-top' }}/>
                Angemeldet bleiben
              </label>
              <Spacer height={20}/>
            </>}
            <Button primary type='submit'
                    disabled={buttonDisabled}>
              {loading
                ? <span style={{ position: 'relative', top: '3px', display: 'block', minWidth: '116px' }}><Spinner
                  size={20}
                  color='#fff'/></span>
                : <>{passwordView ? 'Passwort zurücksetzen' : 'Anmelden'}</>
              }
            </Button>
          </S.Form>
          <S.PasswordResetButton
            onClick={toggleView}>{passwordView ? 'Zur Anmeldung' : 'Passwort vergessen'}</S.PasswordResetButton>
        </Box>
      </Flex>
    </div>
  )
};

export default Login;
