import React, { useContext } from 'react';

import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import { ApiContext } from '@interness/users-addon/src/context/Api';

const Profile = () => {
  const { currentUser } = useContext(ApiContext);

  return (
    <>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Profil</Heading>
      {currentUser
        ? <pre>{JSON.stringify(currentUser, null, 2)}</pre>
        : <p>loading currentUser...</p>
      }
    </>
  )
};

export default Profile;
