import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Unsere Leistungen für Sie`}</h1>
      <ul>
        <li parentName="ul">{`Der FVDH e.V. ist eine berufsständische Vereinigung von Heilpraktikern, Heilpraktikeranwärtern und Heilpraktikern für Psychotherapie.`}</li>
        <li parentName="ul">{`Er ist politisch und in Bezug auf die jeweils herrschenden naturheilkundlichen Schulmeinungen ungebunden.`}</li>
        <li parentName="ul">{`Er arbeitet mit anderen berufsständischen Verbänden zusammen und vertritt die Interessen seiner Mitglieder gegenüber Behörden und gesetzgebenden Körperschaften sowie der Öffentlichkeit.`}</li>
        <li parentName="ul">{`Der FVDH e.V. unterstützt und fördert seine Mitglieder und berät sie in berufsständischen Fragen.`}</li>
        <li parentName="ul">{`Er fördert den Nachwuchs des Heilpraktikerstandes und stellt bei Bedarf fachkundige Prüfungsbeisitzer.`}</li>
        <li parentName="ul">{`Die Mitglieder haben das Recht, das Verbands-Signum zu führen.`}</li>
        <li parentName="ul">{`Diejenigen Mitglieder, die die Eröffnung einer eigenen Praxis melden, erhalten vom Verband gegen Entrichtung der Selbstkosten Verbandsstempel, Mitgliedsnummer, ein Zusatzschild zur Praxiseröffnung, eine Gebühren- und Berufsordnung.`}</li>
        <li parentName="ul">{`Jedes Mitglied erhält einen Mitgliedsausweis.`}</li>
        <li parentName="ul">{`Der FVDH e.V. sieht es als seine Aufgabe, das Bild des Berufsstandes in der Öffentlichkeit mit geeigneten Maßnahmen im positiven Sinne zu beeinflussen sowie in der Öffentlichkeit die Prinzipien naturheilkundlicher Lebensführung und Heilweisen durch geeignete Aufklärungsmaßnahmen zu verankern.`}</li>
        <li parentName="ul">{`Sie möchten Mitglied im FVDH werden? Hier gelangen Sie zum `}<a parentName="li" {...{
            "href": "/service/beitrittserklarung"
          }}>{`Mitgliedsantrag`}</a>{`.`}</li>
      </ul>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      