import styled from '@emotion/styled';

export const Header = styled.div`
  .int-internal-indicator {
    flex-shrink: 1;
    margin-right: 5px;
    svg {
      width: 15px;
      height: 15px;
      fill: ${props => props.theme.brand_color};
    }
  }
  h3 {
    font-weight: bold;
    min-width: 0;
  }
  .int-article-meta {
    display: flex;
    font-weight: bold;
    color: gray;
    font-size: 0.8rem;
    margin-bottom: 20px;
    > * {
      margin-right: 20px;
    }
    svg {
      vertical-align: middle;
      margin-right: 5px;
    }
    .int-article-date {
      margin-bottom: 5px;
    }
    .int-article-category {
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.brand_color}
      }
    }
  }
`;

export const Separator = styled.div`
  width: 90%;
  height: 2px;
  background-color: #e5e5e5;
  margin: 10px auto;
`;

export const ReadMore = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.brand_color};
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #fff;
`;