import React from 'react';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';

import Roles   from '../../Roles/Roles';

const Rechtsberatung = () => {

  return (
    <>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Rechtsberatung</Heading>
      <p>Seit über 20 Jahren ist Herr RA Holzhey als Verbandsanwalt in juristischer und fachlicher Beratung für den
        Berufsverbandverband FVDH und seinen Mitgliedern tätig.</p>
      <p>Den Kollegen Grell können Verbandsmitglieder zu allgemeinen rechtsrelevanten Fragen des Praxisalltags und bei
        Abrechnungsproblemen zu Rate ziehen.</p>
      <p>Vor Inanspruchnahme einer kostenpflichtigen Rechtsberatung durch Hern RA Holzhey kontaktieren Sie vorher bitte,
        zwecks Klärung der Kostenübernahme durch den FVDH, unseren Geschäftsstellenleiter Herrn Siegfried
        Schierstedt.</p>
      <Roles role_name='Rechtsberatung' />
    </>
  )
};

export default Rechtsberatung;
