import React, { useContext, useEffect, useState } from 'react';

import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper        from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spinner        from '@interness/web-core/src/components/modules/Spinner/Spinner';
import { ApiContext } from '@interness/users-addon/src/context/Api';

import Articles from '../../components/Articles/Articles';

const CategoryTemplate = (props) => {
  const { client } = useContext(ApiContext);
  const [categoryRequest, setCategoryRequest] = useState({ loading: false, category: null });
  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    const fetchCategoryInformation = async () => {
      setCategoryRequest({ loading: true });
      const data = await client.getItems('article_category', {
        single: 1,
        'filter[slug]': props.category,
      });
      setCategoryRequest({
        loading: false,
        category: data.data,
      })
    };
    fetchCategoryInformation();
    //eslint-disable-next-line
  }, []);

  const { loading, category } = categoryRequest;

  if (!hasMounted) {
    return null;
  }

  return (
    <Wrapper>
      <Spacer/>
      <Heading subtitle='Kategorie'>
        {loading && <Spinner size={30}/>}
        {category && category.title}
      </Heading>
      <Spacer/>
      <Articles category={props.category}/>
      <Spacer/>
    </Wrapper>
  )
};

export default CategoryTemplate;
