import React   from 'react';

import Spacer   from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading  from '@interness/web-core/src/components/text/Heading/Heading';
import Products from '../../Products/Products';

const Produkte = () => {

  return (
    <>
      <Spacer />
      <Heading subtitle='Mitgliederbereich'>Produkte</Heading>
      <p>Im Folgenden sehen Sie Produkte, die Sie direkt beim FVDH anfordern können. Senden Sie uns dazu einfach eine Nachricht an info@fvdh.de oder rufen Sie uns in unserer Geschäftsstelle unter 0251 – 136886 an.</p>
      <h2>Produkte vom Verband</h2>
      <Products product />
      <h2>Bücherempfehlungen</h2>
      <Products />
    </>
  )
};

export default Produkte;
