import styled from '@emotion/styled';

export const List = styled.ul`
  list-style-type: none;
  li {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
    > div {
      margin-right: 20px;
      p, h3 {
        margin-bottom: 0;
      }
    }
  }
`;