import React                       from 'react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';


const BerufsordnungADT = () => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading>Berufsordnung für Tierheilpraktiker</Heading>
      <h2 id="artikel-1-grunds-tze">Artikel 1   Grundsätze</h2>
      <p>-1- Tierheilpraktiker dienen der Gesundheit der von ihnen behandelten Tiere und der sich aus dem Tierschutz ergebenden Pflichten. Erfahrungen aus der tierheilkundlichen Überlieferung und moderne medizinische Erkenntnisse werden zum Wohl der Tiere und ihrer Halter gleichermaßen angewandt.-2- Tierheilpraktiker verhalten sich in Ausübung des Berufs, wie auch im Privaten stets der Würde des Berufsstands entsprechend und vermeiden alles, was dem Ansehen und der Würde des Standes
        abträglich ist.</p>
      <h2 id="artikel-2-berufspflichten">Artikel 2   Berufspflichten</h2>
      <p>-1- Tierheilpraktiker verpflichten sich, den Beruf gewissenhaft auszuüben und stets nur die Heilmethoden anzuwenden, die nach ihrer Überzeugung auf dem einfachsten und schnellsten Weg und ohne Schädigung
        des Tiers zum Heilerfolg führen oder Linderung verschaffen.-2- Tierheilpraktiker verpflichten sich, bedenkliche Heilmittel in der Praxis nicht anzuwenden, insbesondere nicht bei der Behandlung von Tieren, die der Lebensmittelgewinnung dienen. Tierheilpraktiker sind
        verpflichtet, die Tierhalter auf die Einhaltung der gesetzlich vorgeschriebenen Wartezeiten hinzuweisen.-3- Tierheilpraktiker sollen sich der Grenzen ihres Wissens und Könnens bewußt sein. Insbesondere müssen die Grenzen der gesetzlich vorgeschriebenen Einschränkungen beachtet werden.
        -4- Tierheilpraktiker sind in der Ausübung ihres Berufs frei. Niemand ist berechtigt, die Art der Behandlung eines Tieres vorzuschreiben. Tierheilpraktiker können eine Behandlung ablehnen, wenn sie der Überzeugung sind, daß der betreffende Tierhalter seine Sorgfaltspflicht mißachtet und ein Vertrauensverhältnis zwischen Therapeut und Tierhalter nicht besteht. Die Verpflichtung, in Notfällen zu helfen, bleibt hiervon unberührt.</p>
      <h2 id="artikel-3-schweigepflicht">Artikel 3   Schweigepflicht</h2>
      <p>-1- Tierheilpraktiker verpflichten sich, über alles zu schweigen, was ihnen in Ausübung des Berufs anvertraut oder zugänglich gemacht wird.-2- Unberührt hiervon bleiben Verstöße gegen das Tierschutzgesetz, sowie die Pflicht zur Meldung von Seuchenkrankheiten nach dem Tierseuchengesetz.-3- Tierheilpraktiker müssen alle Personen, die ihnen in Ausübung des Berufs behilflich sind auf die Schweigepflicht hinzuweisen.
        -4- Die Offenbarung eines Berufsgeheimnisses ist dann gerechtfertigt, wenn sie zur Erfüllung einer
        Rechtspflicht notwendig ist, oder das bedrohte Rechtsgut überwiegt.</p>
      <p>-5- Auskünfte an Versicherungen sollen im Einvernehmen mit dem Tierhalter baldigst und nach
        bestem Wissen und Gewissen gegeben werden.</p>
      <h2 id="artikel-4-fortbildungspflicht">Artikel 4   Fortbildungspflicht</h2>
      <p>-1- Tierheilpraktiker sind zu ständiger Fortbildung verpflichtet. Die Fortbildung ist nachzuweisen. Die Berufsorganisationen sind nach Satzung verpflichtet Fortbildungsveranstaltungen anzubieten.-2- Fortbildungsnachweise können nur vom Berufsverband oder durch vom Berufsverband autorisierte Organisationen und Personen ausgegeben werden. Die Nachweise sind aufzubewahren.-3- Fortbildungsnachweise von Organisationen, die nicht vom Berufsverband anerkannt sind,
        zählen nicht als Nachweis im Sinne der Berufsordnung.</p>
      <h2 id="artikel-5-praxis">Artikel 5   Praxis</h2>
      <p>-1- In der Regel übt ein Tierheilpraktiker seine Praxis an seinem Wohnort, bzw. am Ort des ständigen Aufenthalts aus.-2- Räumlichkeiten
        Die Praxisräume sollen den allgemeinen hygienischen Anforderungen entsprechen. Die Räume dürfen außerhalb der Sprechzeiten keinen anderen Zwecken dienen.-3- Die Unterhaltung weiterer Praxen (Zweigpraxen) ist im Rahmen der gesetzlichen Bestimmungen
        zulässig. Alle auf den Namen des Tierheilpraktikers angemeldeten Praxen müssen dem Berufsverband
        gemeldet werden.
        -4- Praxisschilder
        Auf dem Praxisschild ist mindestens anzugeben:</p>
      <ul>
        <li>Die Bezeichnung “Tierheilpraktiker”</li>
        <li>Name des Tierheilpraktikers/ der Tierheilpraktikerin
          Das Schild darf Zusätze über akademische Grade, Titel, Sprechstunden und Telefonnummer enthalten.
          Das Schild darf ferner Hinweise über die Art der Tätigkeit bzw. der behandelten Tierarten enthalten, z.B.
          “Klein – und Großtiere”, “Geflügel”, “Homöopathie”, “Phytotherapie” und ähnliches, jedoch nicht mehr als
          drei Hinweise. Nicht zulässig sind hingegen Bezeichnungen wie “Spezialist”, “Fachtierheilpraktiker für  —”,
          “Diplomtierheilpraktiker” und ähnliches.</li>
      </ul>
      <p>-5- Die Größe des Schilds soll ortsübliche Maße (in der Regel 30 x 50 cm) nicht übersteigen.</p>
      <p>-6- Tierheilpraktiker verzichten auf die Führung akademischer Grade, die nicht an einer Hochschule der Bundesrepublik erworben worden sind, oder von einer zuständigen Behörde zur Führung in der
        Bundesrepublik zugelassen wurden. Entsprechendes gilt für Bezeichnungen wie “Professor”
        “Privatdozent” und ähnliches.</p>
      <p>-7- Für Vordrucke auf Briefbögen, Formularen und Stempeln gilt Art.5, -4- entsprechend.</p>
      <h2 id="artikel-6-werbung">Artikel 6   Werbung</h2>
      <p>-1- Tierheilpraktiker verpflichten sich</p>
      <ul>
        <li>jede unstandesgemäße Werbung zu unterlassen</li>
        <li>Veröffentlichungen jeder Art nicht mit einer Werbung für die eigene Praxis zu verbinden</li>
        <li>Berichte über Behandlungen nur in Fachzeitschriften veröffentlichen zu lassen</li>
        <li>keine Fernbehandlung anzubieten oder durchzuführen (eine Fernbehandlung liegt vor, wenn
          Tierheilpraktiker den Patienten nicht gesehen oder untersucht hat).</li>
        <li>unentgeltliche Behandlungen nicht anzubieten</li>
        <li>Es ist nicht zulässig, nur aufgrund eingesandter Körperflüssigkeiten, Haar- oder anderen Materials
          Diagnosen zu stellen oder Behandlungsempfehlungen zu geben)-2- bei einer Neueröffnung einer Praxis oder bei Umzug können bis zu drei Anzeigen in einer am Ort
          der Praxis erscheinenden Tageszeitung veröffentlicht werden. Für die Anzeige gilt Art. 5. -4- entsprechend-3- Anzeigen in überregionalen Zeitschriften oder Anzeigenblättern über Neuniederlassung, Umzug oder
          Urlaub sind nicht zulässig.
          -4- notwendige Änderungen der Sprechzeiten können durch eine Anzeige in einer am Praxisort
          erscheinenden Tageszeitung veröffentlicht werden</li>
      </ul>
      <p>-5- Für alle Anzeigen in Printmedien gilt: Form und Größe sollen das für solche Anzeigen übliche Maß
        nicht übersteigen.</p>
      <p>-6- Sprechzeiten sollen mindestens an der Praxistür angekündigt werden. Tierheilpraktiker sollen dafür
        Sorge tragen, daß die Tierhalter sie in Notfällen auch außerhalb der Sprechzeiten erreichen können.</p>
      <h2 id="artikel-7-zeugnisse-und-gutachten">Artikel 7   Zeugnisse und Gutachten</h2>
      <p>-1- Zeugnisse und Gutachten sollen Tierheilpraktiker nach bestem Wissen und Gewissen ausfertigen.
        Zweck und Empfänger sind anzugeben.-2- Gutachten und Zeugnisse über Heilmittel dürfen nur in Fachzeitschriften veröffentlicht werden.</p>
      <h2 id="artikel-8-geb-hren">Artikel 8   Gebühren</h2>
      <p>-1- Tierheilpraktiker sind in der Höhe der Gebühren frei.Als Grundlage für die Gebühren soll das von der ADT herausgegebene Gebührenverzeichnis angewandt werden.</p>
      <h2 id="artikel-9-verkauf-verpachtung-einer-praxis">Artikel 9   Verkauf Verpachtung einer Praxis</h2>
      <p>Beim Verkauf einer Praxis dürfen dem Kaufpreis nur die tatsächlich dem Käufer übergebenen Gegenstände
        und Einrichtungen zugrundegelegt werden. Für Tierheilpraktiker ist es standesunwürdig, gewerbsmäßig Praxiskauf und Verkauf zu betreiben. Bei Verkauf oder Verpachtung einer Praxis sollen dem Berufsverband
        die Verträge zu Einsichtnahme und Beratung vorgelegt werden.</p>
      <h2 id="artikel-10-arzneimittel">Artikel 10   Arzneimittel</h2>
      <p>Vertrieb, Abgabe und Anwendung von Arzneimitteln sind nach den gesetzlichen Bestimmungen
        durchzuführen. Insbesondere sind die Vorschriften des Arzneimitteigesetzes zu beachten.Tierheilpraktiker dürfen verschreibungspflichtige Arzneimittel nicht erwerben, anwenden oder abgeben.Apothekenpflichtige Arzneimittel dürfen nur in der Apotheke erworben und in der Ausübung der Praxis angewandt, nicht aber abgegeben werden. Den Umgang mit apothekenpflichtigen Arzneimittel muß bei Eröffnung der Praxis schriftlich der zuständigen Behörde angezeigt werden (§ 67 AMG). Außerdem muß
        über den Erwerb und den Verbrauch der apothekenpflichtigen Arzneimittel Nachweise geführt und auf
        Verlangen vorgelegt werden (§ 64 AMG, AATV).
        Tierheilpraktiker können freiverkäufliche Arzneimittel erwerben und anwenden, abgeben oder Handel damit treiben, wenn der Sachkundenachweis nach § 50 AMG erworben wurde.</p>
      <h2 id="artikel-11-haftpflicht">Artikel 11   Haftpflicht</h2>
      <p>Tierheilpraktiker sollen eine Berufshaftpflicht abschließen. Der Berufsverband kann Versicherungsgesellschaften empfehlen, die günstige Gruppentarife für Tierheilpraktiker anbieten. Von Einleitung und Verlauf von Strafverfahren und Geltendmachung von Schadensersatzansprüchen soll dem Berufsverband unverzüglich und in aller Offenheit Mitteilung gemacht werden.</p>
      <h2 id="artikel-12-meldepflicht-und-anzeigepflicht">Artikel 12   Meldepflicht und Anzeigepflicht</h2>
      <p>Tierheilpraktiker müssen die Eröffnung ihrer Praxis anmelden:
        -1- Beim zuständigen Veterinäramt
        -2- beim zuständigen Finanzamt
        -3- bei der zuständigen unteren Verwaltungsbehörde (Landratsamt)
        -4- bei der zuständigen Arzneimittelüberwachungsbehörde ( siehe Art 10
        -5- bei der Berufsgenossenschaft</p>
      <h2 id="artikel-13-hilfskr-fte">Artikel 13   Hilfskräfte</h2>
      <p>Tierheilpraktiker können in ihrer Praxis Hilfskräfte anstellen. Die Beschäftigten sind sozialversicherungs-
        pflichtig und sind bei der Sozialversicherung anzumelden. Die gesetzlichen Bestimmungen über die
        Beiträge zu den Sozialversicherungen sind zu beachten.</p>
      <h2 id="artikel-14-berufsaufsicht">Artikel 14   Berufsaufsicht</h2>
      <p>-1- Tierheilpraktiker unterstellt sich in seinem eigenen Interesse und im Interesse des Berufsstandes der Aufsicht ihres Berufsverbands.-2- Vom Berufsverband aus gegebenem Anlaß erbetene Auskünfte über Tätigkeit, Arbeitsweise und
        Heilerfolge sollen vom Tierheilpraktiker im Rahmen der Zumutbarkeit beantwortet werden.-3- Der Berufsverband bzw. dessen Beauftragte haben das Recht, sich über die ordnungsgemäße Berufstätigkeit des Tierheilpraktikers an Ort und Stelle zu unterrichten.
        -4- Tierheilpraktiker verpflichten sich, Anordnungen des Berufsverbands nachzukommen. Gegen
        Anordnungen die nach Ansicht des Tierheilpraktikers ungerechtfertigt sind kann er beim zuständigen
        Organ des Berufsverbands Beschwerde einreichen.</p>
      <h2 id="artikel-15-standesdisziplin">Artikel 15   Standesdisziplin</h2>
      <p>-1- Tierheilpraktiker verhalten sich Kollegen gegenüber kollegial und üben keine unsachliche Kritik an Beruskollegen.-2- Unsachgemäße Kritik an den Maßnahmen und Behandlungsmethoden anderer Kollegen sind
        zu vermeiden.</p>
      <h2 id="artikel-16-zuweisung-gegen-entgelt">Artikel 16   Zuweisung gegen Entgelt</h2>
      <p>Es ist nicht standeswürdig, daß Tierheilpraktiker sich gegenseitig Patienten gegen Entgelt zuweisen.</p>
      <h2 id="artikel-17-bestandsberatung">Artikel 17   Bestandsberatung</h2>
      <p>-1- Tierheilpraktiker können in landwirtschaftlichen Betrieben Bestandsberatungen durchführen.-2- Die Bestandsberatung ist nach bestem Wissen und Gewissen durchzuführen. Sie dient der Behandlung
        und Vorbeugung gehäuft auftretender Krankheiten.-3- Die Vergütung richtet sich nach Bestandsgröße und dem erforderlichen Zeitaufwand. Bei der Betreuung eines Betriebs sind die tierseuchenrechtlichen und arzneimittelrechtlichen Bestimmungen einzuhalten.
        -4- Tierheilpraktiker können mit den Tierhaltern Beratungs- und Behandlungsverträge abschließen.</p>
      <h2 id="artikel-18-verst-e-gegen-die-berufsordnung">Artikel 18   Verstöße gegen die Berufsordnung</h2>
      <p>-1- Verstöße gegen die Berufsordnung können im Wege eines ehrengerichtlichen und gerichtlichen
        Verfahrens geahndet werden. Vorher sollte der Versuch einer kollegialen Einigung vor dem zuständigen
        Gremium des Berufsverbands unternommen werden.-2- in einem solchen Verfahren kann darüber entschieden werden, ob ein Tierheilpraktiker wegen
        beruflicher Untüchtigkeit aus dem Berufsverband ausgeschlossen werden soll.-3- Streitigkeiten in Berufsfragen zwischen Berufsverbandsmitgliedern können vom hierfür zuständigen
        Gremium entschieden werden. Der ordentliche Gerichtsweg ist damit nicht ausgeschlossen.
        -4- Verstöße gegen die Berufsordnung können mit einem Bußgeld bis tausend Euro geahndet werden.</p>
      <h2 id="artikel-19-geltungsbereich">Artikel 19   Geltungsbereich</h2>
      <p>Diese Berufsordnung gilt für alle Tierheilpraktiker und basiert auf Grundlage der geltenden rechtlichen Bestimmungen und Gesetze.</p>
      <h2 id="artikel-20-nderungen">Artikel 20   Änderungen</h2>
      <p>Änderungen und Ergänzungen der Berufsordnung können von der Arbeitsgemeinschaft Deutscher Tierheilpraktiker im FVDH e. V. beschlossen werden.</p>
      <h2 id="artikel-21-inkrafttreten">Artikel 21   Inkrafttreten</h2>
      <p>Diese Berufsordnung wurde von der Kooperation der Tierheilpraktikerverbände Deutschlands – unter Mitarbeit der Arbeitsgemeinschaft Deutscher Tierheilpraktiker im FVDH e. V. – beschlossen und tritt am 28.3.1998 in Kraft.</p>
    </Wrapper>
  )
};

export default BerufsordnungADT;

