import styled from '@emotion/styled';

export const Pagination = styled.nav`
  text-align: center;
  margin: auto;
  ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
      //display: inline-block;
      margin: 0 2px;
      a {
        cursor: pointer;
        padding: 5px 15px;
        outline: none;
        transition: background-color 0.1s;
        border-radius: 3px;
        &:hover {
          background-color: rgba(0,0,0, 0.1);
        }
      }
      &.disabled {
        opacity: 0.8;
        a {
          cursor: default;
          &:hover {
           background-color: ${props => props.theme.base_color};
          }
        }
      }
      &.previous {
        margin-right: 20px;
      }
      &.next {
        margin-left: 20px;
      }
      &.selected {
        a {
          background-color: ${props => props.theme.brand_color};
          color: #fff;
        }
      }
    }
  }
`;