import React, { useContext }                                               from 'react';
import PropTypes                                                           from 'prop-types';
import { FaMapMarked, FaPhone, FaEnvelope, FaGlobe, FaPrint, FaMobileAlt } from 'react-icons/fa';

import PhoneNumber                   from '@interness/web-core/src/components/text/PhoneNumber/PhoneNumber';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import useClient                     from '@interness/users-addon/src/hooks/useClient';
import { ApiContext }                from '@interness/users-addon/src/context/Api';

import DImage from '../Image/Image';
import Avatar from '../Avatar/Avatar';
import Error  from '../Error/Error';

import * as S from './styles';

const MemberProfile = ({ memberId, memberData, maxWidths, portrait, isRole, type, style }) => {
  const { isLoggedIn } = useContext(ApiContext);
  const reqParams = {
    'filter[id]': memberId,
    fields: '*.*',
    single: 1,
  };
  let { loading, data: member, error } = useClient('member', reqParams, [memberId], !!!memberData);

  if (memberData) {
    member = memberData;
    error = false;
    loading = false;
  }

  return (
    <>
      {loading && <SpinnerContainer><Spinner size={20}/></SpinnerContainer>}
      {member &&
      <S.Profile maxWidth={maxWidths} type={type} style={style}>
        <div>
          {portrait && member.portrait && <Avatar>
            <DImage className='int-profile-image' name={member.portrait.private_hash} width={150} height={150}
                    quality={100} fit='crop'
                    alt={`${member.first_name} ${member.last_name}`}/>
          </Avatar>}
          {!isRole && member.title && <i className='int-member-title'>{member.title}</i>}
          <h4 style={{ fontWeight: 'bold' }}>{member.first_name} {member.last_name}</h4>
          {member.job_title &&
          <i className='int-member-job'>{member.job_title}</i>}
        </div>
        <div>
          <ul>
            <li><FaMapMarked title='Addresse'/> {member.street} {member.zip} {member.place}</li>
            {member.phone_number &&
            <li><FaPhone title='Telefonnummer'/> <PhoneNumber number={member.phone_number}/></li>}
            {isLoggedIn && isRole && member.mobile_number &&
            <li><FaMobileAlt title='Handynummer'/> <PhoneNumber number={member.mobile_number}/></li>}
            {member.fax_number &&
            <li><FaPrint title='Fax'/> <PhoneNumber number={member.fax_number} callable={false}/></li>}
            {member.email &&
            <li><FaEnvelope title='E-Mail'/> <Link external to={`mailto:${member.email}`}>{member.email}</Link></li>}
            {isRole && member.fvdh_email &&
            <li><FaEnvelope title='FVDH E-Mail'/> <Link external
                                                        to={`mailto:${member.fvdh_email}`}>{member.fvdh_email}</Link>
            </li>}
            {member.homepage &&
            <li><FaGlobe title='Homepage'/> <Link external to={member.homepage}>{member.homepage}</Link></li>}
          </ul>
          {member.role_description &&
          <p style={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: member.role_description }}/>
          }
        </div>
      </S.Profile>
      }
      {error && <Error message='Mitglied nicht gefunden'/>}
    </>
  )
};

MemberProfile.propTypes = {
  portrait: PropTypes.bool,
  isRole: PropTypes.bool,
  type: PropTypes.oneOf(['card', 'row']),
  memberId: PropTypes.number,
  memberData: PropTypes.object,
  maxWidths: PropTypes.string,
};

MemberProfile.defaultProps = {
  portrait: true,
  isRole: false,
  type: 'card'
};

export default MemberProfile;