import React from 'react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';


const GebuehrenverzeichnisADT = () => {
  return (
    <Wrapper>
      <Spacer />
      <Heading>Gebührenverzeichnis für Tierheilpraktiker</Heading>
      <table style={{ width: '100%' }} border="1" cellSpacing="1" cellPadding="4">
        <tbody>
        <tr>
          <td width="25%"></td>
          <td colSpan="2"><strong>Hunde, Katzen, sonstige Kleintiere</strong></td>
          <td colSpan="2"><strong>Nutztiere</strong></td>
          <td colSpan="2"><strong>Pferde</strong></td>
        </tr>
        <tr>
          <td width="25%"><strong>Untersuchungskosten</strong></td>
          <td width="12%">
            <div align="right">von €</div>
          </td>
          <td width="12%">
            <div align="right">bis €</div>
          </td>
          <td width="12%">
            <div align="right">von €</div>
          </td>
          <td width="12%">
            <div align="right">bis €</div>
          </td>
          <td width="12%">
            <div align="right">von €</div>
          </td>
          <td width="12%">
            <div align="right">bis €</div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td width="25%">1.0 Allgemeinuntersuchung</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">30,65</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">7,70</div>
          </td>
          <td width="12%">
            <div align="right">51,20</div>
          </td>
        </tr>
        <tr>
          <td width="25%">1.1. homöopatische Fallaufnahme</td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">25,55</div>
          </td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">51,55</div>
          </td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">51,20</div>
          </td>
        </tr>
        <tr>
          <td width="25%">1.2. homöopatische Repertorisation</td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">52,00</div>
          </td>
          <td width="12%">
            <div align="right">25,55</div>
          </td>
          <td width="12%">
            <div align="right">51,15</div>
          </td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
          <td width="12%">
            <div align="right">25,55</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Spezielle Untersuchungen</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">2.1 spez. Atemwegunt. bei Pferden</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">25,55</div>
          </td>
        </tr>
        <tr>
          <td width="25%">2.2. Lahmheitsunt. bei Pferden</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">25,25</div>
          </td>
        </tr>
        <tr>
          <td width="25%">2.5 Trächtigkeitsunt. bei Nutztieren</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
          <td width="12%">
            <div align="right">25,55</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Untersuchungen mit Geräten</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">3.1 BFD Bioelektr. Funktionsdiagn.</td>
          <td width="12%">
            <div align="right">51,15</div>
          </td>
          <td width="12%">
            <div align="right">80,00</div>
          </td>
          <td colSpan="4">
            <div align="right">je nach Zeit und Geräteaufwand für alle Tiere</div>
          </td>
        </tr>
        <tr>
          <td width="25%">3.2 Resonanzanalyse</td>
          <td width="12%">
            <div align="right">46,10</div>
          </td>
          <td width="12%">
            <div align="right">155,00</div>
          </td>
          <td colSpan="4">
            <div align="right">je nach Zeit und Geräteaufwand für alle Tiere</div>
          </td>
        </tr>
        <tr>
          <td width="25%">3.3 Ultraschallunt.</td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
          <td width="12%">
            <div align="right">105,00</div>
          </td>
          <td colSpan="4">
            <div align="right">je nach Zeit und Geräteaufwand für alle Tiere</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Beratung</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">4.0 kurze Beratung auch per Telefon</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">20,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">4.1 ausführliche Beratung</td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">4.2 Futterberatung, schrift. Futterplan</td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
          <td colSpan="4">
            <div align="right">oder 8,00 bis 23,00 je angefangene halbe Stunde</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Wegegebühren</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">5.1 Entfernungskilometer</td>
          <td width="12%">
            <div align="right">0,40</div>
          </td>
          <td width="12%">
            <div align="right">1,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">1,00</div>
          </td>
          <td width="12%">
            <div align="right">2,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">5.2 Entfernungspauschale bis 10 km</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">5.3 Entfernungspauschale bis 20 km</td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Laborgebühren</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">6.1 Urin-Mehrfachteststreifen</td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">1,55</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">6.2 Blutzucker</td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">2,60</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">6.3 Einfache mikroskopische Unt.</td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td width="25%">6.4 Einfache Kotuntunt.</td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">20,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Injektionen</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">7.1 Inj.i.m. incl. Medikament</td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.1 Inj.i.m. ohne Medikament</td>
          <td width="12%">
            <div align="right">3,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.2 Inj.i.v. incl. Medikament</td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">25,60</div>
          </td>
          <td>
            <div align="right">5,10</div>
          </td>
          <td>
            <div align="right">20,50</div>
          </td>
          <td>
            <div align="right">10,25</div>
          </td>
          <td>
            <div align="right">25,80</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.2 Inj.i.v. ohne Medikament</td>
          <td width="12%">
            <div align="right">3,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.3 Inj.s.c. incl. Medikament</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,50</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.3 Inj.s.c. ohne Medikament</td>
          <td width="12%">
            <div align="right">1,55</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.4 Blutentnahme aus der Vene</td>
          <td width="12%">
            <div align="right">1,55</div>
          </td>
          <td width="12%">
            <div align="right">20,00</div>
          </td>
          <td>
            <div align="right">5,00</div>
          </td>
          <td>
            <div align="right">5,10</div>
          </td>
          <td>
            <div align="right">5,00</div>
          </td>
          <td>
            <div align="right">20,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.5 Infusion</td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">20,45</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
          <td width="12%">
            <div align="right">12,80</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.6 Neuraltherapie</td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.7 Eigenblutinjektionen</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">65,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">7.8 Aderlaß</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td>
            <div align="right">7,80</div>
          </td>
          <td>
            <div align="right">15,35</div>
          </td>
          <td>
            <div align="right">10,25</div>
          </td>
          <td>
            <div align="right">25,80</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Akupunktur</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">8.1 Nadelakupunktur</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">100,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">8.2 Laserakupunktur</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">8.3 Elektroakupunktur</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">20,50</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">25,80</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>besondere Heilverfahren</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">9.1 Baunscheidt-Behandlung</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">18,00</div>
          </td>
          <td width="12%">
            <div align="right">25,80</div>
          </td>
        </tr>
        <tr>
          <td width="25%">9.2 Bachblütenbehandlung</td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">80,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">60,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">9.3 Blutegelbehandlung</td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">62,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">18,00</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Therapie mit physik.-technischen Geräten</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">10.1 HOT</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">51,13</div>
          </td>
        </tr>
        <tr>
          <td width="25%">10.2 Ozontherapie</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">51,13</div>
          </td>
        </tr>
        <tr>
          <td width="25%">10.3 Magnetfeldtherapie</td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">15,60</div>
          </td>
          <td width="12%">
            <div align="right">51,15</div>
          </td>
        </tr>
        <tr>
          <td width="25%">10.4 Laserbehandlung</td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">10,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">18,00</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">Phytotherapie</td>
          <td width="12%">
            <div align="right">10,00</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
          <td>
            <div align="right">10,00</div>
          </td>
          <td>
            <div align="right">30,00</div>
          </td>
          <td>
            <div align="right">10,00</div>
          </td>
          <td>
            <div align="right">30,00</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Wundbehandlung</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">11.1 einfache Wundbehandlung</td>
          <td width="12%">
            <div align="right">1,55</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">1,55</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,23</div>
          </td>
        </tr>
        <tr>
          <td width="25%">11.2 Beh. e. größeren Wundw Rein./Nacht</td>
          <td width="12%">
            <div align="right">10,00</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">15,35</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">11.3 Nachbeh. Verbandwechsel Desinf.</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">25,56</div>
          </td>
        </tr>
        <tr>
          <td width="25%">11.4 aufwendiger Kompress./Stützverb.</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">25,56</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Zahnbehandlung</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">12.1 Zähneraspeln</td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">7,80</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">12.2 Zahnsteinentfernen</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Sonstige Behandlungen</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">13.1 Otitisbehandlung</td>
          <td width="12%">
            <div align="right">7,50</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">13.2 Ohrreinigung</td>
          <td width="12%">
            <div align="right">5,00</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">13.3 Analbeutelspülung</td>
          <td width="12%">
            <div align="right">5,17</div>
          </td>
          <td width="12%">
            <div align="right">25,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td width="25%">13.4 Krallenschneiden</td>
          <td width="12%">
            <div align="right">2,50</div>
          </td>
          <td width="12%">
            <div align="right">20,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%"></td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Manuelle Therapie</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">14.1 Lymphdrainage</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">60,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">14.2 Osteopathische Bahandlung</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">25,80</div>
          </td>
        </tr>
        <tr>
          <td width="25%">14.3 Massagen</td>
          <td width="12%">
            <div align="right">5,10</div>
          </td>
          <td width="12%">
            <div align="right">30,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">10,25</div>
          </td>
          <td width="12%">
            <div align="right">50,00</div>
          </td>
        </tr>
        <tr>
          <td colSpan="7">
            <h3>Verhaltentherapeutische Behandlung</h3>
          </td>
        </tr>
        <tr>
          <td width="25%">Erstamnese</td>
          <td width="12%">
            <div align="right">18,00</div>
          </td>
          <td width="12%">
            <div align="right">150,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">18,00</div>
          </td>
          <td width="12%">
            <div align="right">120,00</div>
          </td>
        </tr>
        <tr>
          <td width="25%">Folgekonsultationen</td>
          <td width="12%">
            <div align="right">15,00</div>
          </td>
          <td width="12%">
            <div align="right">40,00</div>
          </td>
          <td width="12%"></td>
          <td width="12%"></td>
          <td width="12%">
            <div align="right">20,00</div>
          </td>
          <td width="12%">
            <p align="right">40,00</p>
          </td>
        </tr>
        </tbody>
      </table>
    </Wrapper>
  )
};

export default GebuehrenverzeichnisADT;
