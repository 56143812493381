import React         from 'react';
import { Flex, Box } from '@chakra-ui/react';

import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import MemberProfile from '../components/MemberProfile/MemberProfile';
import Seminars      from '../components/Seminars/Seminars';
import Error         from '../components/Error/Error';

const WorkgroupTemplate = ({ slug }) => {
  const reqParams = {
    single: 1,
    fields: '*,seminars.*.*.*',
    'filter[slug]': slug
  };
  const { loading, data: workgroup, error } = useClient('workgroup', reqParams, [slug], true);

  const seminars = [];
  if (!loading && workgroup) {
    workgroup.seminars.forEach(seminar => seminars.push(seminar.seminar));
  }

  return (
    <div>
      <Wrapper>
        <Spacer/>
        {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
        {error && <Error message='Arbeitsgruppe konnte nicht gefunden werden.' />}
        {workgroup && <div>
          <Heading>{workgroup.title}</Heading>
          <Spacer/>
          <Flex flexWrap='wrap'>
            <Box width={['100%', '40%', '40%', '40%']}>
              <h2>Seminarleiter/in für {workgroup.title}</h2>
              <MemberProfile memberId={workgroup.leader.id} maxWidths={'420px'} style={{ marginLeft: '0' }}/>
            </Box>
            <Box width={['100%', '60%', '60%', '60%']}>
              <h2>Termine / Seminare</h2>
              <Seminars seminars={seminars}/>
            </Box>
          </Flex>
        </div>}
        <Spacer/>
      </Wrapper>
    </div>
  )
};

export default WorkgroupTemplate;
