import React from 'react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import useClient                     from '@interness/users-addon/src/hooks/useClient';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import Error    from '../../components/Error/Error';

const Schulen = () => {

  const params = {
    status: 'published',
    filter: {
      adt: 0
    },
    fields: '*.*'
  };

  let { loading, data: schools, error } = useClient('school', params, null, true);

  return (
    <Wrapper>
      <Spacer/>
      <Heading>Zertifizierte Schulen und Institute</Heading>
      {error && <Error message='Schulen konnten nicht gefunden werden'/>}
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {schools && schools.map(school => (
        <div key={school.display_name}>
          <h2>{school.display_name}</h2>
          <div dangerouslySetInnerHTML={{ __html: school.content}} />
          <Spacer />
        </div>
      ))}
    </Wrapper>
  )
};

export default Schulen;
