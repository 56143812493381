import React      from 'react';
import { Router } from '@reach/router';

import WorkgroupTemplate from '../templates/Workgroup';
import NotFound          from '../components/NotFound';

const Blog = () => {
  return (
    <Router primary={false}>
      <WorkgroupTemplate path={'/fvdh-arbeitskreise/:slug'}/>
      <NotFound default/>
    </Router>
  )
};

export default Blog;
