import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Berufsordnung`}</h1>
      <p><em parentName="p"><strong parentName="em">{`Anmerkung:`}</strong></em></p>
      <p><em parentName="p">{`Diese Berufsordnung wurde als Satzungsbestandteil von der Mitgliederversammlung am 31. Oktober 1992 beschlossen. Sie stellt, soweit keine Gesetze, Verordnungen sowie die einschlägige Rechtssprechung angesprochen werden, kein verbindliches Recht dar und unterliegt somit den Satzungsbestimmungen entsprechend dem Vereinsrecht. Eine grundsätzliche und allgemeine Rechtsverbindlichkeit kann hieraus nicht abgeleitet werden.`}</em></p>
      <h2 {...{
        "id": "artikel-1-berufsgrundsatze"
      }}>{`Artikel 1: Berufsgrundsätze`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker dient der Gesundheit des einzelnen Menschen und des ganzen Volkes. Er erfüllt seine Aufgabe nach bestem Gewissen sowie nach den Erfahrungen der heilkundlichen Überlieferungen und dem jeweiligen Erkenntnisstand der Heilkunde. Der Heilpraktiker hat den hohen ethischen Anforderungen seines freien Heilberufs zu dienen und alles zu vermeiden, was dem Ansehen seines Berufsstandes schadet.`}</li>
        <li parentName="ol">{`Der Heilpraktiker übt einen freien Beruf aus. Er behandelt seine Patienten eigenverantwortlich. Er muß in seiner Eigenverantwortlichkeit stets für den Patienten erkennbar sein.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-2-berufspflichten"
      }}>{`Artikel 2: Berufspflichten`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker verpflichtet sich, seinen Beruf gewissenhaft auszuüben. Bei seinen Patienten wendet er stets diejenigen Heilmethoden an, die nach seiner Überzeugung einfach und kostengünstig zum Heilerfolg oder zur Linderung der Krankheit führen können.`}</li>
        <li parentName="ol">{`Der Heilpraktiker hat sich der Grenzen seines Wissens und Könnens bewußt zu sein. Er ist verpflichtet, sich eine ausreichende Sachkunde über die von ihm angewandten Diagnose- und Behandlungsverfahren einschließlich ihrer Risiken, vor allem die richtigen Techniken für deren gefahrlose Anwendung anzueignen.`}</li>
        <li parentName="ol">{`Der Heilpraktiker ist verpflichtet, sich über die für die Berufsausübung geltenden Vorschriften zu unterrichten und sie zu beachten. Soweit ihm gesetzlich die Untersuchung oder Behandlung einzelner Leiden und Krankheiten sowie andere Tätigkeiten untersagt sind, sind die Beschränkungen zu beachten.`}</li>
        <li parentName="ol">{`Der Heilpraktiker ist bei der Ausübung seines Berufes frei. Er kann die Behandlung ablehnen. Seine Verpflichtung, in Notfällen zu helfen, bleibt davon unberührt.`}</li>
        <li parentName="ol">{`Der Heilpraktiker darf kostenlose oder briefliche Behandlung (Fernbehandlung) nicht anbieten. Fernbehandlung liegt u.a. vor, wenn der Heilpraktiker den Kranken nicht gesehen und untersucht hat. Es ist ferner nicht zulässig, Diagnosen zu stellen und Arzneimittel oder Heilverfahren zu empfehlen, wenn ausschließlich eingesandtes Untersuchungsmaterial oder andere Unterlagen zur Verfügung stehen.`}</li>
        <li parentName="ol">{`In allen die Öffentlichkeit berührenden Standesfragen gilt der Grundsatz der Wahrung von Takt und Zurückhaltung.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-3-schweigepflicht"
      }}>{`Artikel 3: Schweigepflicht`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker verpflichtet sich, über alles Schweigen zu bewahren, was ihm bei der Ausübung seines Berufes anvertraut oder zugänglich gemacht wird.`}</li>
        <li parentName="ol">{`Der Heilpraktiker hat seine Gehilfen oder jene Personen, die Zur Vorbereitung auf den Beruf unter seiner Aufsicht tätig sind, über die Pflicht zur Verschwiegenheit zu belehren und dies schriftlich festzuhalten.`}</li>
        <li parentName="ol">{`Der Heilpraktiker hat die Pflicht zur Verschwiegenheit auch seinen Familienangehörigen gegenüber zu beachten.`}</li>
        <li parentName="ol">{`Der Heilpraktiker darf ein Berufsgeheimnis nur offenbaren, wenn der Patient ihn von der Schweigepflicht entbunden hat. Dies gilt auch gegenüber den Angehörigen eines Patienten, wenn nicht die Art der Erkrankung oder die Behandlung eine Mitteilung notwendig macht.`}</li>
        <li parentName="ol">{`Auskünfte über den Gesundheitszustand eines Arbeitnehmers an seinen Arbeitgeber dürfen nur mit Zustimmung des Ersteren erfolgen.`}</li>
        <li parentName="ol">{`Notwendige Auskünfte an Krankenversicherungen müssen nach bestem Wissen und Gewissen gegeben werden.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-4-aufklarungs--dokumentations--und-sorgfaltspflicht"
      }}>{`Artikel 4: Aufklärungs-, Dokumentations- und Sorgfaltspflicht`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker stellt sein ganzes Wissen und Können in den Dienst seines Berufes und wendet jede mögliche Sorgfalt in der Betreuung seiner Patienten an.`}</li>
        <li parentName="ol">{`Der Patient ist über seine Erkrankung sowie über die Art und voraussichtliche Dauer der Behandlung aufzuklären. Dabei entscheidet der Heilpraktiker unter Berücksichtigung des körperlichen und seelischen Zustandes des Patienten nach seiner Erfahrung, inwieweit der Kranke unter seinem derzeitigen Zustand aufzuklären ist. Ebenso muß der Kranke bei einer vorgesehenen Behandlung auf eventuelle Risiken aufmerksam gemacht werden.`}</li>
        <li parentName="ol">{`Im Rahmen der wirtschaftlichen Aufklärungspflicht wird er die Patienten nach bestem Wissen und Gewissen über die voraussichtlich entstehenden ungefähren Behandlungskosten unterrichten.`}</li>
        <li parentName="ol">{`In Fällen, in denen eine Spezialuntersuchung, eine Operation oder eine sonstige Heilmaßnahme erforderlich ist, die der Heilpraktiker selbst nicht vornehmen kann, ist rechtzeitig mit allem Nachdruck auf die Vornahme einer solchen Maßnahme hinzuweisen. Führt auch eine neue, eindringliche Warnung an den Patienten und dessen Angehörige nicht zum Ziel, so kann die Ablehnung der Behandlung bzw. Weiterbehandlung geboten sein. Über diesen Vorgang sollte der Heilpraktiker in eigenem Interesse eine Niederschrift fertigen.`}</li>
        <li parentName="ol">{`Der Heilpraktiker ist zur Dokumentation der wichtigsten Daten einer Krankenbehandlung verpflichtet.`}</li>
        <li parentName="ol">{`Heilungsversprechen sind nicht zulässig.`}</li>
        <li parentName="ol">{`Die Ausstellung von Attesten ohne vorgenommene Untersuchung ist nicht zulässig.`}</li>
        <li parentName="ol">{`In Bescheinigungen und Befundberichten hat der Heilpraktiker seiner Überzeugung gewissenhaft Ausdruck zu verleihen.`}</li>
        <li parentName="ol">{`Im Rahmen einer eventuellen gutachterlichen Tätigkeit für Gerichte, private Krankenversicherungen, Beihilfestellen oder andere Institutionen hat sich der Heilpraktiker in seinen gutachterlichen Aussagen ausschließlich auf die sachliche Beurteilung der jeweiligen Behandlung zu beschränken.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-5-fortbildungspflicht"
      }}>{`Artikel 5: Fortbildungspflicht`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker ist zur ständigen Fortbildung verpflichtet. Die Fortbildung ist nachzuweisen. Die Berufsorganisationen sind nach ihren Satzungen verpflichtet, fachliche Fortbildung anzubieten.`}</li>
        <li parentName="ol">{`Die Verbände geben Fortbildungsnachweise aus.`}</li>
        <li parentName="ol">{`Fortbildungsnachweise und auch Fachkundenachweise für besondere Fachdisziplinen können nur anerkannt werden, wenn sie von einem Berufsverband oder von durch ihn anerkannte Institutionen ausgestellt sind.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-6-praxisort"
      }}>{`Artikel 6: Praxisort`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker übt seine Tätigkeit am Ort seiner Niederlassung aus. Einem Ruf nach auswärts darf Folge geleistet werden (Hausbesuch). Es ist nicht zulässig, Patienten in Sammelbestellungen oder einzeln an einen anderen Ort als den der Niederlassung zur Behandlung zu bestellen.`}</li>
        <li parentName="ol">{`Ändert der Heilpraktiker seinen Praxisort, teilt er dies unter Angabe der neuen Anschrift den zuständigen Behörden sowie seinem Verband mit.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-7-praxisraume"
      }}>{`Artikel 7: Praxisräume`}</h2>
      <ol>
        <li parentName="ol">{`Die Praxisraume müssen den hygienischen und gesetzlichen Anforderungen genügen.`}</li>
        <li parentName="ol">{`Die Vertraulichkeit der Gespräche und Behandlung muß gewährleistet sein.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-8-werbung"
      }}>{`Artikel 8: Werbung`}</h2>
      <p>{`Der Heilpraktiker unterliegt keinem generellen gesetzlich normierten Werbeverbot. Jedoch hat er bei jeder unmittelbaren oder mittelbaren Werbung, sei es für seine Person, seine Praxis oder seine Tätigkeit, die gesetzlichen Bestimmungen, insbesondere diejenigen des “Gesetzes über den unlauteren Wettbewerb (UWG)”, des Gesetzes über die “Werbung auf dem Gebiete des Heilwesens (HWG)”, die wesentliche werbliche Einschränkungen enthalten, zu beachten. Die einschlägige laufende Rechtsprechung ist zu berücksichtigen. Bezüglich UWG und HWG wird ausdrücklich auf den Anhang verwiesen.`}</p>
      <ol>
        <li parentName="ol">{`Unzulässig ist jede irreführende Werbung, die mit den guten Sitten der Heilberufe nicht zu vereinbaren ist. (UWG, §1).`}</li>
        <li parentName="ol">{`Die Mitwirkung des Heilpraktikers an aufklärenden Veröffentlichungen medizinischen Inhaltes in Presse, Funk und Fernsehen sowie anläßlich von Vorträgen sollte so erfolgen, daß sich seine Mitwirkung auf sachliche Informationen beschrankt.`}</li>
        <li parentName="ol">{`Er verpflichtet sich, darauf hinzuwirken, daß jede unzulässige Werbung, die ohne seine Kenntnis oder Mitwirkung erfolgt ist, richtiggestellt wird und künftig unterbleibt.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-9-praxisschilder"
      }}>{`Artikel 9: Praxisschilder`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker hat auf seinem Praxisschild seinen Namen und die Berufsbezeichnung Heilpraktiker anzugeben. Evtl. weitere Angaben sollten sich auf Sprechzeiten, Fernsprechnummer, Stockwerk, Privatadresse, eine Bezeichnung wie “Naturheilpraxis” und bis zu höchstens drei Verfahren, für die der Heilpraktiker über die besonderen Qualifikationen verfügt, beschränken. Die Angabe der Verfahren sollte bei allen Verwendungsmöglichkeiten identisch sein.`}</li>
        <li parentName="ol">{`Das Praxisschild ist in unaufdringlicher Form zu gestalten. Die Größe sollte sich den örtlichen Gepflogenheiten (etwa 35 cm x 50 cm) anpassen. Je nach örtlicher Gegebenheit können zwei Praxisschilder erforderlich werden.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-10-drucksachen-und-stempel"
      }}>{`Artikel 10: Drucksachen und Stempel`}</h2>
      <p>{`Die Angaben für Drucksachen und Stempel sollten über die in Artikel 9 gemachten Angaben nicht hinausgehen.`}</p>
      <h2 {...{
        "id": "artikel-11-eintragung-in-verzeichnisse-und-sonderverzeichnisse"
      }}>{`Artikel 11: Eintragung in Verzeichnisse und Sonderverzeichnisse`}</h2>
      <p>{`Die Eintragung sollte nur im Einzugsbereich des Niederlassungsortes erfolgen. Über den kostenlosen Eintrag hinausgehende Informationen sollten sich auf höchstens fünf Zeilen und die in Artikel 9 erwähnten Angaben beschränken.`}</p>
      <h2 {...{
        "id": "artikel-12-inserate"
      }}>{`Artikel 12: Inserate`}</h2>
      <p>{`Inserate dienen der Information des Patienten und dürfen keinen darüber hinausgehenden unsachgemäßen, mit den guten Sitten des Heilberufs nicht zu vereinbarenden werbenden Charakter aufweisen. Ihnen sollte in der Regel ein besonderer Anlaß zugrunde liegen, insbesondere Neuniederlassung, Umzug, längere Abwesenheit oder Änderung der Telefonnummer. Für Inserate sollten folgende Hinweise beachtet werden:`}</p>
      <ol>
        <li parentName="ol">{`Eine Anzeige nach der Niederlassung, nach einem Umzug oder Änderung der Telefon-Nummer sollte – außer den Angaben der Praxisstätte nicht mehr als die in Artikel 9 angeführten Angaben enthalten und – nur in den im Einzugsbereich des Niederlassungsortes erscheinenden Tages-, Orts- und Stadtteilzeitungen (Werbezeitungen mit redaktionellem Teil) innerhalb der ersten drei Monate nach der Niederlassung oder dem Umzug veröffentlicht werden.`}</li>
        <li parentName="ol">{`Eine Hinweisanzeige vor und nach einer längeren Abwesenheit (mindestens eine Woche) in einer der unter Absatz 1 genannten Zeitungen sollte – außer den Daten, welche den Zeitpunkt der Praxisunterbrechung angeben, keine weiteren als die in Artikel 9 erwähnten Angaben enthalten.`}</li>
        <li parentName="ol">{`Die Anzeige sollte in Form und Größe dem Informationszweck entsprechen und die Maße einspaltig 60 mm hoch oder zweispaltig 30 mm hoch nicht überschreiten.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-13-besondere-bezeichnungen"
      }}>{`Artikel 13: Besondere Bezeichnungen`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker verzichtet auf die Bezeichnung “Spezialist” sowie auf andere Zusatzbezeichnungen, die ihn gegenüber seinen Standeskollegen hervorheben. Er darf neben der Berufsbezeichnung “Heilpraktiker” keine Bezeichnungen wie z. B. “Akupunkteur”, “Chiropraktiker”, “Homöopath”, “Psychologe”, “Psychotherapeut” u.a. führen, die durch diese Koppelung den Eindruck einer ebenfalls gesetzlich und/oder behördlich genehmigten Berufsausübung bzw. Berufsbezeichnung wie der des Heilpraktikers erwecken.`}</li>
        <li parentName="ol">{`Akademische Grade dürfen nur in Verbindung mit der Fakultätsbezeichnung verwendet werden. Ausländische akademische Grade, Titel und Bezeichnungen wie Professor, dürfen nur geführt werden, wenn das zuständige Ministerium eine entsprechende Genehmigung erteilt hat. Sie sind so zu führen, daß ihre ausländische Herkunft erkennbar ist.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-14-hausbesuche"
      }}>{`Artikel 14: Hausbesuche`}</h2>
      <ol>
        <li parentName="ol">{`Bei Krankenbesuchen muß jeder Patient in dessen Wohnung oder dem vorübergehenden Aufenthaltsort behandelt werden.`}</li>
        <li parentName="ol">{`Patienten in Kliniken, Kurheimen usw. können nur mit vorherigem Einverständnis des leitenden Arztes oder Heilpraktikers beraten, untersucht und behandelt werden.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-15-heilpraktiker-und-arzneimittel"
      }}>{`Artikel 15: Heilpraktiker und Arzneimittel`}</h2>
      <p>{`Die Herstellung sowie der Verkauf von Arzneimitteln unterliegt den gesetzlichen Bestimmungen.`}</p>
      <h2 {...{
        "id": "artikel-16-verordnung-von-arzneimitteln-provisionen-rabatte"
      }}>{`Artikel 16: Verordnung von Arzneimitteln, Provisionen, Rabatte`}</h2>
      <ol>
        <li parentName="ol">{`Verbandszugehörigkeiten sollten auf Rezepten, Rechnungen u. a. durch Abdruck des Mitgliedsstempels kenntlich gemacht werden.`}</li>
        <li parentName="ol">{`Der Heilpraktiker läßt sich für die Verordnung oder Empfehlung von Arzneimitteln, medizinischen Geräten usw. keine Vergütung oder sonstige Vergünstigungen gewahren.`}</li>
        <li parentName="ol">{`Patienten dürfen ohne hinreichenden Grund nicht an bestimmte Apotheken verwiesen werden.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-17-haftpflicht"
      }}>{`Artikel 17: Haftpflicht`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker verpflichtet sich, eine ausreichende Berufshaftpflichtversicherung abzuschließen. Der Abschluß einer Strafrechtsschutzversicherung wird empfohlen.`}</li>
        <li parentName="ol">{`Im eigenen Interesse sollte der Heilpraktiker von der Einleitung und dem Fortgang eines Strafverfahrens sowie von der Geltendmachung berufsbedingter Schadensersatzansprüche gegen ihn unverzüglich seinem Verband schriftlich Mitteilung machen. Die erforderlichen Angaben sind dabei lückenlos und in aller Offenheit darzulegen.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-18-meldepflicht"
      }}>{`Artikel 18: Meldepflicht`}</h2>
      <p>{`Der Heilpraktiker hat sich mit der Praxisaufnahme nach den gesetzlichen Vorschriften anzumelden (z.B. Gesundheitsamt, Finanzamt).`}</p>
      <h2 {...{
        "id": "artikel-19-beschaftigung-von-hilfskraften"
      }}>{`Artikel 19: Beschäftigung von Hilfskräften`}</h2>
      <p>{`Beschäftigt der Heilpraktiker in seiner Praxis Angestellte (Sprechstundenhilfen usw.), so hat er die für Beschäftigungsverhältnisse geltenden Vorschriften zu beachten.`}</p>
      <h2 {...{
        "id": "artikel-20-berufsinsignien"
      }}>{`Artikel 20: Berufsinsignien`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker erhält von seiner Standesorganisation einen Berufsausweis und einen Mitgliederstempel. Beide bleiben Eigentum des ausgebenden Verbandes und müssen bei Beendigung der Mitgliedschaft zurückgegeben werden. Unberechtigter Besitz und Gebrauch werden gerichtlich verfolgt. Die Berufsinsignien werden nur an Heilpraktiker ausgegeben.`}</li>
        <li parentName="ol">{`Der Berufsausweis dient dazu, sich bei Behörden und in erforderlichen Situationen als Heilpraktiker ausweisen zu können.`}</li>
        <li parentName="ol">{`Ausweis und Stempel müssen die Mitgliedsnummer und den Namen des Verbandes (Berufsorganisation) enthalten. Weitere evtl. Vorschriften über Ausgabe usw. sind in den Verbandsstatuten zu regeln.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-21-berufsaufsicht"
      }}>{`Artikel 21: Berufsaufsicht`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker unterstellt sich im Interesse des Berufsstandes der Berufsaufsicht seines Verbandes (Berufsorganisation).`}</li>
        <li parentName="ol">{`Es liegt im eigenen Interesse des Heilpraktikers – von seinem Verband erbetene Auskünfte über seine Praxistätigkeit wahrheitsgemäß zu erteilen, – den gewählten Vertretern seiner Berufsorganisation bzw. deren autorisierten Beauftragten es zu ermöglichen, sich über seine geordnete Berufstätigkeit an Ort und Stelle zu unterrichten, – notwendigen Anordnungen seines Verbandes nachzukommen, wobei gegen Anordnungen, die nach Ansicht des Heilpraktikers nicht gerechtfertigt sind, entsprechend der Satzung des zuständigen Verbandes Einspruch erhoben werden kann, – bei Ausübung spezieller Behandlungsmethoden wie Akupunktur, Chiropraktik, Osteopathie u. a., die besondere Kenntnisse und Fähigkeiten erfordern, im Bedarfsfalle einen entsprechenden Befähigungsnachweis zu erbringen.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-22-prufungen"
      }}>{`Artikel 22: Prüfungen`}</h2>
      <ol>
        <li parentName="ol">{`Eine Prüfung kann im Interesse des Standes vom Verband als notwendig erachtet werden, wenn aufgrund von Tatsachen erhebliche Zweifel am Wissen und an der Befähigung eines Heilpraktikers mit Gefahren für den Patienten entstehen. Wird einem Prüfungsverlangen nicht entsprochen, berechtigt dies den Verband zu satzungsgemäßen Maßnahmen.`}</li>
        <li parentName="ol">{`Die Bestätigung als Mitglied eines Verbandes kann von einer kollegialen Prüfung abhängig gemacht werden.`}</li>
        <li parentName="ol">{`Über jede Prüfung ist eine Niederschrift zu fertigen, die von allen Mitgliedern der Prüfungskommission zu unterzeichnen ist.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-23-standesdisziplin"
      }}>{`Artikel 23: Standesdisziplin`}</h2>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker als Mitglied eines Verbandes verpflichtet sich zur Standesdisziplin. Kollegen begegnet er sowohl am Krankenbett als auch in privatem Rahmen mit Kollegialität.`}</li>
        <li parentName="ol">{`Herabsetzende Äußerungen über die Person, die Behandlungsweise oder das berufliche Wissen eines Berufskollegen sind zu unterlassen.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-24-hinzuziehung-eines-zweiten-heilpraktikers"
      }}>{`Artikel 24: Hinzuziehung eines zweiten Heilpraktikers`}</h2>
      <ol>
        <li parentName="ol">{`Sofern es vom Kranken oder dessen Angehörigen gewünscht wird, oder wenn der behandelnde Heilpraktiker unter Zustimmung des Kranken oder der Angehörigen es befürwortet, können weitere Heilpraktiker zur gemeinsamen Behandlung einbezogen werden.`}</li>
        <li parentName="ol">{`Wird ein weiterer Heilpraktiker einbezogen, so darf er nur die Untersuchung durchführen. Er darf nicht die weitere Behandlung vornehmen, es sei denn, der Patient selbst, seine Angehörigen oder der bisher behandelnde Heilpraktiker im Einvernehmen mit dem Patienten wünschen weiterhin seine Tätigkeit.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-25-vertrauliche-beratung"
      }}>{`Artikel 25: Vertrauliche Beratung`}</h2>
      <ol>
        <li parentName="ol">{`Der Meinungsaustausch und die Beratung von mehreren einbezogenen Heilpraktikern müssen geheim bleiben und dürfen nicht in Gegenwart des Patienten stattfinden; auch dürfen die Angehörigen bei der Beratung nicht zugegen sein.`}</li>
        <li parentName="ol">{`Das Ergebnis der gemeinsamen Beratung soll in der Regel vom behandelnden Heilpraktiker dem Patienten mitgeteilt werden.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-26-zuweisung-gegen-entgelt"
      }}>{`Artikel 26: Zuweisung gegen Entgelt`}</h2>
      <p>{`Es ist standeswidrig, wenn Heilpraktiker sich Patienten gegen Entgelt zuweisen.`}</p>
      <h2 {...{
        "id": "artikel-27-vertretung"
      }}>{`Artikel 27: Vertretung`}</h2>
      <p>{`Jeder Heilpraktiker sorgt bei vorübergehender oder lang andauernder Verhinderung dafür, daß die notwendige Weiterbehandlung von Patienten in dringenden Krankheitsfällen sichergestellt ist.`}</p>
      <h2 {...{
        "id": "artikel-28-verstosse-gegen-die-berufsordnung"
      }}>{`Artikel 28: Verstöße gegen die Berufsordnung`}</h2>
      <ol>
        <li parentName="ol">{`Verstöße gegen die Berufsordnung können im Wege eines satzungsgemäßen Verfahrens geahndet werden. Vorher sollte jedoch immer der Versuch einer kollegialen Bereinigung durch die satzungsgemäß zuständigen Berufsvertreter unternommen werden.`}</li>
        <li parentName="ol">{`In einem solchen Verfahren kann auch darüber entschieden werden, ob ein Heilpraktiker im Interesse des Standes aus dem Verband auszuschließen ist.`}</li>
        <li parentName="ol">{`Die Bestimmungen des HeilprG vom 17.2.1939 und der Durchführungsverordnungen sowie anderer gesetzlicher Bestimmungen werden hiervon nicht berührt.`}</li>
      </ol>
      <h2 {...{
        "id": "artikel-29-inkrafttreten-der-boh"
      }}>{`Artikel 29: Inkrafttreten der BOH`}</h2>
      <ol>
        <li parentName="ol">{`Diese Berufsordnung wurde satzungsgemäß beschlossen.`}</li>
        <li parentName="ol">{`Sie tritt am 31. Oktober 1992 in Kraft.`}</li>
      </ol>
      <hr></hr>
      <h1>{`Anhang – Zur Berufsordnung für Heilpraktiker (BOH) – Gesetzliche Beschränkungen in der Werbung`}</h1>
      <p><strong parentName="p">{`A. Auszug aus Gesetz gegen den unlauteren Wettbewerb vom 7. Juni 1909 (RGBl. 499), zuletzt geändert am 25.10.94 (BGBl. I S. 3082)`}</strong></p>
      <h3 {...{
        "id": "1-generalklausel"
      }}>{`§1 `}{`[Generalklausel]`}</h3>
      <p>{`Wer im geschäftlichen Verkehr zu Zwecken des Wettbewerbs Handlungen vornimmt, die gegen die guten Sitten verstoßen, kann auf Unterlassung und Schadensersatz in Anspruch genommen werden.`}</p>
      <h3 {...{
        "id": "3-unerlaubte-werbung"
      }}>{`§3 `}{`[Unerlaubte Werbung]`}</h3>
      <p>{`Wer im geschäftlichen Verkehr zu Zwecken des Wettbewerbes über geschäftliche Verhältnisse, insbesondere über die Beschaffenheit, den Ursprung, die Herstellungsart oder die Preisbemessung einzelner Waren oder gewerblicher Leistungen oder des gesamten Angebots, über Preislisten, über die Art des Bezugs oder die Bezugsquelle von Waren, über den Besitz von Auszeichnungen, über den Anlaß oder den Zweck des Verkaufs oder über die Menge der Vorräte irreführende Angaben macht, kann auf Unterlassung der Angaben in Anspruch genommen werden.`}</p>
      <h3 {...{
        "id": "4-strafbare-werbung"
      }}>{`§4 `}{`[Strafbare Werbung]`}</h3>
      <p>{`(1) Wer in der Absicht, den Anschein eines besonders günstigen Angebots hervorzurufen, in öffentlichen Bekanntmachungen oder in Mitteilungen, die für einen größeren Kreis von Personen bestimmt sind, über geschäftliche Verhältnisse, insbesondere über die Beschaffenheit, den Ursprung, die Herstellungsart oder die Preisbemessung von Waren oder gewerblichen Leistungen, über die Art des Bezugs oder die Bezugsquelle von Waren, über den Besitz von Auszeichnungen, über den Anlaß oder den Zweck des Verkaufs oder über die Menge der Vorräte wissentlich unwahre und zur Irreführung geeignete Angaben macht, wird mit Freiheitsstrafe bis zu einem Jahr oder mit Geldstrafe bestraft.`}</p>
      <h2 {...{
        "id": "b-auszug-aus-gesetz-uber-die-werbung-auf-dem-gebiete-des-heilwesens-hwg"
      }}>{`B. Auszug aus Gesetz über die Werbung auf dem Gebiete des Heilwesens (HWG)`}</h2>
      <p>{`In der Fassung der Bekanntmachung vom 19.10.94 (BGBl. I S. 3068) zuletzt geändert am 25.10.94 (BGBl. I S. 3082)`}</p>
      <h3 {...{
        "id": "-1"
      }}>{`§ 1`}</h3>
      <p>{`(1) Dieses Gesetz findet Anwendung auf die Werbung für`}</p>
      <ol>
        <li parentName="ol">{`Arzneimittel im Sinne des § 2 des Arzneimittelgesetzes,`}</li>
        <li parentName="ol">{`andere Mittel, Verfahren, Behandlungen und Gegenstände, soweit sich die Werbeaussage auf die Erkennung, Beseitigung oder Linderung von Krankheiten, Leiden, Körperschäden oder krankhaften Beschwerden bei Mensch oder Tier bezieht.`}</li>
      </ol>
      <h3 {...{
        "id": "-2"
      }}>{`§ 2`}</h3>
      <p>{`Fachkreise im Sinne dieses Gesetzes sind Angehörige der Heilberufe oder des Heilgewerbes, Einrichtungen, die der Gesundheit von Mensch oder Tier dienen, oder sonstige Personen, soweit sie mit Arzneimitteln, Verfahren, Behandlungen, Gegenständen oder anderen Mitteln erlaubterweise Handel treiben oder sie in Ausübung ihres Berufes anwenden. § 3 Unzulässig ist eine irreführende Werbung. Eine Irreführung liegt insbesondere dann vor,`}</p>
      <ol>
        <li parentName="ol">{`wenn Arzneimittel, Verfahren, Behandlungen, Gegenständen oder anderen Mitteln eine therapeutische Wirksamkeit oder Wirkungen beigelegt werden, die sie nicht haben,`}</li>
        <li parentName="ol">{`wenn fälschlich der Eindruck erweckt wird, daß a) ein Erfolg mit Sicherheit erwartet werden kann, b) bei bestimmungsgemäßem oder längerem Gebrauch keine schädlichen Wirkungen eintreten, c) die Werbung nicht zu Zwecken des Wettbewerbs veranstaltet wird,`}</li>
        <li parentName="ol">{`wenn unwahre oder zur Täuschung geeignete Angaben
a) über die Zusammensetzung oder Beschaffenheit von Arzneimitteln, Gegenständen oder anderen Mitteln oder über die Art und Weise der Verfahren oder Behandlungen oder
b) über die Person, Vorbildung, Befähigung oder Erfolge des Herstellers, Erfinders oder der für sie tätigen oder tätig gewesenen Personen gemacht werden.`}</li>
      </ol>
      <h3 {...{
        "id": "-3a"
      }}>{`§ 3a`}</h3>
      <p>{`Unzulässig ist eine Werbung für Arzneimittel, die der Pflicht zur Zulassung unterliegen und die nicht nach den arzneimittelrechtlichen Vorschriften zugelassen sind oder als zugelassen gelten.`}</p>
      <h3 {...{
        "id": "-5-zusammenfassung"
      }}>{`§ 5 (Zusammenfassung)`}</h3>
      <p>{`besagt, daß bei homöopathischen Arzneimitteln nicht mit der Angabe von Anwendungsgebieten geworben werden darf.`}</p>
      <h3 {...{
        "id": "-8-2-zusammenfassung"
      }}>{`§ 8 (2) (Zusammenfassung)`}</h3>
      <p>{`Unzulässig ist die Werbung, bestimmte Arzneimittel im Wege der Einzeleinfuhr zu beziehen, die gemäß`}</p>
      <h3 {...{
        "id": "-732-und-3"
      }}>{`§ 73(2) und (3)`}</h3>
      <p>{`nur zum persönlichen Bedarf aus EG-Ländern oder durch Apotheken auf Einzelanforderung eingeführt werden dürfen.`}</p>
      <h3 {...{
        "id": "-9"
      }}>{`§ 9`}</h3>
      <p>{`Unzulässig ist eine Werbung für die Erkennung oder Behandlung von Krankheiten, Leiden, Körperschäden oder krankhaften Beschwerden, die nicht auf eigener Wahrnehmung an dem zu behandelnden Menschen oder Tier beruht (Fernbehandlung).`}</p>
      <h3 {...{
        "id": "-11"
      }}>{`§ 11`}</h3>
      <p>{`Außerhalb der Fachkreise darf für Arzneimittel, Verfahren, Behandlungen, Gegenstände oder andere Mittel nicht geworben werden:`}</p>
      <ol>
        <li parentName="ol">{`mit Gutachten, Zeugnissen, wissenschaftlichen oder fachlichen Veröffentlichungen sowie mit Hinweisen darauf,`}</li>
        <li parentName="ol">{`mit Angaben, daß das Arzneimittel, das Verfahren, die Behandlung, der Gegenstand oder das andere Mittel ärztlich, zahnärztlich, tierärztlich oder anderweitig fachlich empfohlen oder geprüft ist oder angewendet wird,`}</li>
        <li parentName="ol">{`mit der Wiedergabe von Krankengeschichten sowie mit Hinweisen darauf,`}</li>
        <li parentName="ol">{`mit der bildlichen Darstellung von Personen in der Berufskleidung oder bei der Ausübung der Tätigkeit von Angehörigen der Heilberufe, des Heilgewerbes oder des Arzneimittelhandels,`}</li>
        <li parentName="ol">{`mit der bildlichen Darstellung
a) von Veränderungen des menschlichen Körpers oder seiner Teile durch Krankheiten, Leiden oder Körperschäden,
b) der Wirkung eines Arzneimittels, eines Verfahrens, einer Behandlung, eines Gegenstandes oder eines anderen Mittels durch vergleichende Darstellung des Körperzustandes oder des Aussehens vor und nach der Anwendung,
c) des Wirkungsvorganges eines Arzneimittels, eines Verfahrens, einer Behandlung, eines Gegenstandes oder eines anderen Mittels am menschlichen Körper oder an seinen Teilen,`}</li>
        <li parentName="ol">{`mit fremd- oder fachsprachlichen Bezeichnungen, soweit sie nicht in den allgemeinen deutschen Sprachgebrauch eingegangen sind,`}</li>
        <li parentName="ol">{`mit einer Werbeaussage, die geeignet ist, Angstgefühle hervorzurufen oder auszunutzen,`}</li>
        <li parentName="ol">{`durch Werbevorträge, mit denen ein Feilbieten oder eine Entgegennahme von Anschriften verbunden ist,`}</li>
        <li parentName="ol">{`mit Veröffentlichungen, deren Werbezweck mißverständlich oder nicht deutlich erkennbar ist,`}</li>
        <li parentName="ol">{`mit Veröffentlichungen, die dazu anleiten, bestimmte Krankheiten, Leiden, Körperschäden oder krampfhafte Beschwerden beim Menschen selbst zu erkennen und mit den in der Werbung bezeichneten Arzneimitteln, Gegenständen, Verfahren, Behandlungen oder anderen Mitteln zu behandeln, sowie mit entsprechenden Anleitungen in audiovisuellen Medien,`}</li>
        <li parentName="ol">{`mit Äußerungen Dritter, insbesondere mit Dank-, Anerkennungs- oder Empfehlungsschreiben, oder mit Hinweisen auf solche Äußerungen,`}</li>
        <li parentName="ol">{`mit Werbemaßnahmen, die sich ausschließlich oder überwiegend an Kinder oder an Jugendliche unter 14 Jahren richten,`}</li>
        <li parentName="ol">{`mit Preisausschreiben, Verlosungen oder andere Verfahren, deren Ergebnis vom Zufall abhängig ist,`}</li>
        <li parentName="ol">{`durch die nicht verlangte Abgabe von Mustern oder Proben oder durch Gutscheine dafür.`}</li>
        <li parentName="ol">{`durch die nicht verlangte Abgabe von Mustern oder Proben von anderen Mitteln oder Gegenständen oder Gutscheine dafür.`}</li>
      </ol>
      <h3 {...{
        "id": "-12"
      }}>{`§ 12`}</h3>
      <p>{`(1) Die Werbung für Arzneimittel außerhalb der Fachkreise darf sich nicht auf die Erkennung, Verhütung, Beseitigung oder Linderung der in der Anlage zu diesem Gesetz aufgeführten Krankheiten oder Leiden beim Menschen oder Tier beziehen.`}</p>
      <p>{`(2) Die Werbung für andere Mittel, Verfahren, Behandlungen oder Gegenstände außerhalb der Fachkreise darf sich nicht auf die Erkennung, Beseitigung oder Linderung dieser Krankheiten oder Leiden beziehen. Dies gilt nicht für die Werbung für Verfahren oder Behandlungen in Heilbädern, Kurorten und Kuranstalten.
Anlage zu § 12 HGW Krankheiten und Leiden, auf die sich die Werbung gem. § 12 nicht beziehen darf A.`}</p>
      <p>{`Krankheiten und Leiden beim Menschen:`}</p>
      <ol>
        <li parentName="ol">{`Meldepflichtige Krankheiten nach dem Bundes-Seuchengesetz`}</li>
        <li parentName="ol">{`Geschwulstkrankheiten,`}</li>
        <li parentName="ol">{`Krankheiten des Stoffwechsels und der inneren Sekretion, ausgenommen Vitamin- und Mineralstoffmangel und alimentäre Fettsucht,`}</li>
        <li parentName="ol">{`Krankheiten des Blutes und der blutbildenden Organe, ausgenommen Eisenmangelanämie,`}</li>
        <li parentName="ol">{`organische Krankheiten
a) des Nervensystems,
b) der Augen und Ohren,
c) des Herzens und der Gefäße, ausgenommen allgemeine Arteriosklerose, Varikose und Frostbeulen,
d) der Leber und des Pankreas,
e) der Harn- und Geschlechtsorgane,`}</li>
        <li parentName="ol">{`Geschwüre des Magens und des Darms,`}</li>
        <li parentName="ol">{`Epilepsie,`}</li>
        <li parentName="ol">{`Geisteskrankheiten,`}</li>
        <li parentName="ol">{`Trunksucht,`}</li>
        <li parentName="ol">{`Krankhafte Komplikationen der Schwangerschaft, der Entbindung und des Wochenbetts`}</li>
      </ol>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      