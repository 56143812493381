import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Ausbildung`}</h1>
      <h2 {...{
        "id": "geregelte-osteopathieausbildung-beim-fvdh"
      }}>{`Geregelte Osteopathieausbildung beim FVDH`}</h2>
      <p>{`Der FVDH bietet eine Ausbildung mit der Anerkennung Osteopathie für seine Mitglieder an. Die Forderung an uns, uns fortzubilden sowie unser Wunsch, die Qualität unserer Ausbildungen zu beweisen, findet sich in folgenden Forderungen wieder:`}</p>
      <ol>
        <li parentName="ol">{`Der Heilpraktiker ist zur ständigen Fortbildung verpflichtet. Die Fortbildung ist nachzuweisen. Die Berufsorganisationen sind nach ihren Satzungen verpflichtet, fachliche Fortbildungen anzubieten.`}</li>
        <li parentName="ol">{`Die Verbände geben Fortbildungsnachweise aus.`}</li>
        <li parentName="ol">{`Forbildungsnachweise und auch Fachkundenachweise für besondere Fachdisziplinen können nur anerkannt werden, wenn sie von einem Berufsverband oder von durch ihn anerkannten Institutionen ausgestellt sind.`}</li>
      </ol>
      <p>{`Die Zertifizierung erfolgt nach den Richtlinien des Dachverbandes Deutscher Heilpraktikerverbände e. V. (Fachverband Deutscher Heilpraktiker e. V., Freier Verband Deutscher Heilpraktiker e. V., Union Deutscher Heilpraktiker e. V., Verband Deutscher Heilpraktiker e. V.).`}</p>
      <p>{`Das Curriculum der Ausbildung entspricht den Curriculi der Ausbildungsstellen für Heilpraktiker, Ärzte und Physiotherapeuten nach den Vorlagen der DROM (Deutsches Register für Osteopathische Medizin), BAO (Bundesarbeitsgemeinschaft Osteopathie) und der DGOM (Deutsche Gesellschaft für Osteopathie).`}</p>
      <p>{`Die Ausbildung umfasst insgesamt 1350 Unterrichtseinheiten (UE) als Kontaktstunden a 45 Min., 150 UE stehen zur freien Verfügung. Bereits erworbene Kenntnisse der Teilnehmer/in werden durch den FVDH überprüft und können anerkannt werden. Der Unterricht teilt sich in 800 Stunden Theorie und 550 Stunden Praxis auf. Am Ende der Ausbildung reicht der Teilnehmer/in 6 Fallbeschreibungen im Bereich der Osteopathie ein, die vom Verband beurteilt werden. Die Ausbildung in der Osteopathie schließt mit einer Prüfung vor dem FVDH ab. Der Teilnehmer erhält am Ende ein Zeugnis und eine Urkunde in der Fachausbildung Osteopathie. Wir hoffen somit den Anfragen unserer Mitglieder nach einer hoch qualifizierten Osteopathieausbildung gerecht zu werden und ihnen eine gute Basis in der Konkurrenz zu den Mitstreitern anderer Ausbildungsanbieter zu geben.`}</p>
      <h2 {...{
        "id": "kurzfassung-des-curriculums-fachausbildung-osteopathie-des-fvdh"
      }}>{`Kurzfassung des Curriculums Fachausbildung Osteopathie des FVDH:`}</h2>
      <h3 {...{
        "id": "1-zugangsvorraussetzung"
      }}>{`1`}{`.`}{` Zugangsvorraussetzung:`}</h3>
      <p>{`Vorraussetzung für die Ausbildung ist die staatliche Überprüfung zum Heilpraktiker durch das zuständige Gesundheitsamt.`}</p>
      <h3 {...{
        "id": "2-art-und-dauer-der-ausbildung"
      }}>{`2`}{`.`}{` Art und Dauer der Ausbildung:`}</h3>
      <p>{`Die Ausbildung erfolgt berufsbegleitend in Blockseminaren mit höchstens 10 Stunden a 45 Minuten pro Tag.`}</p>
      <h3 {...{
        "id": "3-die-ausbildungsstundenzahl"
      }}>{`3`}{`.`}{` Die Ausbildungsstundenzahl:`}</h3>
      <p>{`Unsere Ausbildung umfasst insgesamt 1350 Unterrichtseinheiten a 45 Minuten.`}</p>
      <h3 {...{
        "id": "4-einteilung-in-theoretische-und-praktische-bereiche"
      }}>{`4`}{`.`}{` Einteilung in theoretische und praktische Bereiche`}</h3>
      <p>{`Die Ausbildung teilt sich in einen medizinischen und einen osteopathischen Bereich auf. Die Ausbildung in den medizinischen Grundlagen umfasst 800 UEs, in der Osteopathie werden 550 Stunden unterrichtet. Unter der Berücksichtigung der freien Unterrichtseinheiten ergibt sich folgende Aufteilung:`}</p>
      <p>{`Theorie: 800 UE, davon 60 UE zur freien Gestaltung`}</p>
      <p>{`Praxis: 500 UE, davon 90 zur freien Gestaltung`}</p>
      <h3 {...{
        "id": "41-medizinischer-bereich-800-ue"
      }}>{`4.1 Medizinischer Bereich, 800 UE:`}</h3>
      <ul>
        <li parentName="ul">{`Anatomie`}</li>
        <li parentName="ul">{`Physiologie/Pathophysiologie`}</li>
        <li parentName="ul">{`Embryologie`}</li>
        <li parentName="ul">{`Biomechanik`}</li>
        <li parentName="ul">{`Innere Medizin`}</li>
        <li parentName="ul">{`Infektionslehre, meldepflichtige Erkrankungen, Seuchengesetz`}</li>
        <li parentName="ul">{`Orthopädie/Traumatologie`}</li>
        <li parentName="ul">{`Neurologie`}</li>
        <li parentName="ul">{`Pädiatrie`}</li>
        <li parentName="ul">{`Gynäkologie`}</li>
        <li parentName="ul">{`Urologie`}</li>
        <li parentName="ul">{`Psychologie/Psychiatrie`}</li>
        <li parentName="ul">{`Ernährungslehre`}</li>
        <li parentName="ul">{`Klinische Chemie`}</li>
        <li parentName="ul">{`Pharmakologie`}</li>
        <li parentName="ul">{`Radiologie`}</li>
        <li parentName="ul">{`Differentialdiagnostik`}</li>
        <li parentName="ul">{`Notfallmaßnahmen`}</li>
        <li parentName="ul">{`Ethik`}</li>
        <li parentName="ul">{`Medizinische Methodologie`}</li>
        <li parentName="ul">{`Rechts und Berufskunde`}</li>
      </ul>
      <p>{`Es werden sämtliche vor der Ausbildung abgeleisteten Fortbildungen des jeweiligen Bewerbers vom Verband überprüft und von diesem, wenn gerechtfertigt, anerkannt.`}</p>
      <h3 {...{
        "id": "42-osteopathischer-bereich-550-ue"
      }}>{`4.2 Osteopathischer Bereich, 550 UE:`}</h3>
      <ul>
        <li parentName="ul">{`Geschichte der Osteopathie`}</li>
        <li parentName="ul">{`Philosophie, Konzepte und Prinzipien der Osteopathie`}</li>
        <li parentName="ul">{`Anamnese und Befunderhebung`}</li>
        <li parentName="ul">{`Diagnostik und Therapie der Parietalen Osteopathie (PO)`}</li>
        <li parentName="ul">{`Diagnostik und Therapie der Visceralen Osteopathie (VO)`}</li>
        <li parentName="ul">{`Diagnostik und Therapie der Kraniosachralen Osteopathie (KSO)`}</li>
        <li parentName="ul">{`Manuelle Techniken wie Chiropraktik, Manualtherapie … können auf die Osteopathie PO angerechnet werden. Diese Fortbildungen der Bewerber/innen werden vom Verband überprüft und durch diesen, wenn gerechtfertigt, anerkannt. Der Unterricht teilt sich insgesamt auf Kontaktunterricht, Eigenunterricht zu Hause, Wiederholung und Abschlussprüfung auf.`}</li>
      </ul>
      <h3 {...{
        "id": "kasuistik"
      }}>{`Kasuistik`}</h3>
      <p>{`Zum Abschluss soll der Teilnehmer/in 6 Fallbeschreibungen im Bereich der Osteopathie verfassen. Diese werden durch die Lehrbeauftragten des Verbandes oder die vom Verband autorisierte fortbildende Schule beurteilt.`}</p>
      <h3 {...{
        "id": "prufung"
      }}>{`Prüfung`}</h3>
      <p>{`Die Prüfung wird durch die Lehrbeauftragten und Vorsitzenden des Verbandes oder die vom Verband autorisierte fortbildende Schule abgenommen. Sie beinhaltet folgende Bereiche:`}</p>
      <h4 {...{
        "id": "1-ein-schriftlicher-teil"
      }}>{`1`}{`.`}{` Ein schriftlicher Teil:`}</h4>
      <p>{`(Dauer: 2 Stunden a 60 Min., multiple choice und offene Fragen, 60 % = bestanden; 60-69 % = ausreichend = 4, 70-79 % = befriedigend = 3, 80-89 % = gut = 2, 90-100 % = sehr gut = 1)`}</p>
      <h4 {...{
        "id": "ein-praktisch-mundlicher-technikteil"
      }}>{`Ein praktisch-mündlicher Technikteil`}</h4>
      <p>{`(in allen Teilbereichen PO, PV, PKS, Prüfungsdauer: 30 Min.)`}</p>
      <h4 {...{
        "id": "ein-differentialdiagnostischer-teil"
      }}>{`Ein differentialdiagnostischer Teil`}</h4>
      <p>{`(Prüfungszeit 30 Min., Überprüfung der red flags)`}</p>
      <h4 {...{
        "id": "ein-klinischer-teil"
      }}>{`Ein klinischer Teil`}</h4>
      <p>{`(Prüfungszeit bis zu einer Stunde, an einem unbekannten Patienten)`}</p>
      <p>{`Die sich addierenden vier Noten ergeben im Schnitt die Gesamtbeurteilung des Teilnehmers. Bei Nichtbestehen legt der Prüfungsausschuss die Anforderungen an die Vorbereitung für die Wiederholungsprüfung fest. Die Prüfung kann dreimal in den nicht bestandenen Teilbereichen wiederholt werden.`}</p>
      <h3 {...{
        "id": "das-zeugnis"
      }}>{`Das Zeugnis`}</h3>
      <p>{`Das Zeugnis beinhaltet alle Vier Teilbereichsnoten sowie die Gesamtnote des Teilnehmers.`}</p>
      <h3 {...{
        "id": "die-urkunde"
      }}>{`Die Urkunde`}</h3>
      <p>{`Nach erfolgreich abgelegter Prüfung und sofern kein berechtigter Zweifel an der persönlichen Zuverlässigkeit, der körperlichen, geistigen und charakterlichen Eignung besteht, wird die Urkunde ausgehändigt.`}</p>
      <p>{`Wir hoffen, somit den Anfragen unserer Mitglieder nach einer hoch qualifizierten Osteopathie-Ausbildung gerecht zu werden und freuen uns, Ihnen eine gute Basis in der Konkurrenz zu den Mitstreitern im Gesundheitswesen zu geben.`}</p>
      <h3 {...{
        "id": "ansprechpartnerin"
      }}>{`Ansprechpartnerin:`}</h3>
      <p>{`Prof. Dr. rer. medic. Beate Kranz-Opgen-Rhein
PT/HP/DO FVDH®
Lehrbeauftragte des FVDH e. V.
Viehofstr. 43, 52066 Aachen
Tel.: 0241-706419
E-mail: `}<a parentName="p" {...{
          "href": "mailto:beate.kranz-opgen-rhein@t-online.de",
          "rel": "nofollow noopener noreferrer external"
        }}>{`beate.kranz-opgen-rhein@t-online.de`}</a></p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      