import React         from 'react';
import { Flex, Box } from '@chakra-ui/react';

import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import Error from '../../Error/Error';

const Presse = () => {

  const { loading, data, error } = useClient('press', {
    fields: '*.*.*.*',
    single: '1'
  }, null, true);

  return (
    <>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Presse</Heading>
      <Flex flexWrap={'wrap'}>
        <Box width={['100%', '50%', '50%', '50%']}>
          <h2>Pressekontakt</h2>
          <p>Bundesgeschäftsstelle Münster<br/>
            Siegfried Schierstedt, Vorstandsmitglied<br/>
            Matthias Mertler, Vorstandsmitglied<br/>
            Weseler Str. 19 – 21<br/>
            48151 Münster</p>

          <p>Tel.: 0251 – 136886<br/>
            Fax: 0251 – 392736<br/>
            E-Mail: info@fvdh.de</p>
        </Box>
        <Box width={['100%', '50%', '50%', '50%']}>
          <h2>Logo</h2>
          {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
          {error && <Error message='Keine Daten gefunden'/>}
          {data &&
          <div>
            <img src={data.press_files[1].file.data.full_url} alt='FVDH Logo'/>
            <h3>Downloads</h3>
            <ul>
              {data.press_files.map(({ file }) => <li key={file.id}>
                <Link external to={file.data.full_url}>{file.title} ({file.type.split('/')[1].toUpperCase()})</Link>
              </li>)}
            </ul>
          </div>}
        </Box>
      </Flex>
    </>
  )
};

export default Presse;
