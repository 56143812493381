import React               from 'react';
import { useCurrentWidth } from 'react-socks';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import MemberProfile from '../MemberProfile/MemberProfile';
import Error         from '../Error/Error';

const Roles = ({ role_name }) => {
  const width = useCurrentWidth();
  const { loading, data, error } = useClient('fvdh_role', {
    fields: '*.*,members.*.*.*',
    'filter[title]': role_name,
    single: '1'
  }, null, true);
  return (
    <div>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {error && <Error message='Rolle konnte nicht gefunden werden.' />}
      {data &&
      <div>
        {data.members.map((member, i) => (
          <MemberProfile key={member.id} memberData={member.member} isRole type={width > 1000 ? 'row' : 'card'}/>
        ))}
      </div>
      }
    </div>
  )
};

export default Roles;
