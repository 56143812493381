import styled from '@emotion/styled';

export const Form = styled.form`
  text-align: center;
  max-width: ${props => props.width ? props.width : '420px'};
  margin: auto;
`;

export const Input = styled.label`
  display: block;
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;
  .label {
    position: absolute;
    top: 20px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 16, 75, 0.5);
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: text;
    user-select: none;
  }
  .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 16, 75, 0.04);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }
  input {
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 2px solid rgba(0, 16, 75, 0.25);
    background: #fff;
    color: #00104B;
    transition: all 0.15s ease;
    &:hover {
      background: #fff;
    }
    &:not(:placeholder-shown) + .label {
      color: rgba(0, 16, 75, 0.5);
      transform: translate3d(0, -12px, 0) scale(0.75);
    }
    &:focus {
      background: rgba(0, 16, 75, 0.04);
      outline: none;
      border-color: ${props => props.theme.brand_color};
    }
    &:focus + .label {
      color: ${props => props.theme.brand_color};
      transform: translate3d(0, -12px, 0) scale(0.75);
    }
    &:focus + .label + .focus-bg {
      transform: scaleX(1);
      transition: all 0.15s ease;
    }
  }
`;

export const PasswordResetButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  display: block;
  margin: 20px auto 0 auto;
  color: ${props => props.theme.brand_color};
  background-color: unset;
`;