import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Roles from '../../components/Roles/Roles';
import { Flex, Box } from '@chakra-ui/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Schiedsstelle des FVDH e.V.`}</h1>
      <h2 {...{
        "id": "was-ist-eine-schiedsstelle"
      }}>{`Was ist eine Schiedsstelle?`}</h2>
      <p>{`Die Schiedsstelle ist eine neutrale Schlichtungsstelle für Patienten und Heilpraktiker. Sie regelt Beschwerden und Streitigkeiten im Vorverfahren ohne gerichtliche Auseinandersetzung.`}</p>
      <p>{`Sie ist bemüht, Meinungsverschiedenheiten zwischen Heilpraktiker/in und Patient/in, neutral und unbürokratisch mit Sachverstand in beider Einvernehmen gütlich zu regeln.`}</p>
      <h2 {...{
        "id": "wann-kann-die-schiedsstelle-eingeschaltet-werden"
      }}>{`Wann kann die Schiedsstelle eingeschaltet werden?`}</h2>
      <p>{`Die Schiedsstelle kann bei Streitigkeiten, die in der Heilpraktikerpraxis durch Therapieverfahren, Gebührenabrechnungen oder anderen praxisrelevanten Auseinandersetzungen entstanden sind, eingeschaltet werden.`}</p>
      <p>{`Sie befasst sich nicht mit Streitigkeiten, die bei Gericht anhängig sind.`}</p>
      <p>{`Die Entscheidungen der Schlichtungsstellen sind Feststellungen oder Empfehlungen. Wenn der/die Patient/in oder Heilpraktiker/in mit der Entscheidung des Schlichtungsvorschlags der Schiedsstelle nicht einverstanden ist, kann er/sie den ordentlichen Rechtsweg beschreiten.`}</p>
      <h2 {...{
        "id": "schiedsstelle-munster"
      }}>{`Schiedsstelle Münster`}</h2>
      <Roles role_name='Schiedstelle Münster' mdxType="Roles" />
      <h2 {...{
        "id": "schiedsstelle-kalkar"
      }}>{`Schiedsstelle Kalkar`}</h2>
      <Roles role_name='Schiedstelle Kalkar' mdxType="Roles" />
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      