import styled from '@emotion/styled';

const Avatar = styled.div`
  img {
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }
`;

export default Avatar;
