import React from 'react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Articles from '../../Articles/Articles';

const MembersHome = (props) => {
  return (
    <div>
      <Spacer/>
      <Heading subtitle={props.category === 'arzneimittelkommission' ? 'Arzneimittelkommission des Dachverbands Deutscher Heilpraktikerverbände e.V. ' : 'Mitgliederbereich'}>{props.heading}</Heading>
      <Articles category={props.category ? props.category : null}/>
    </div>
  )
};

export default MembersHome;