import styled  from '@emotion/styled';
import { css } from '@emotion/react'

export const Wrapper = styled.div`
  max-width: 1200px;
  width: 95%;
  height: 170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const Header = styled.header`
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.30);
`;

export const Logo = styled.div`
  max-width: ${props => props.maxWidth && props.maxWidth < 300 ? `${props.maxWidth}px` : '300px'};
  max-height: 120px;
  flex-shrink: 0;
  flex-grow: 1;

  img {
    transition: height 0.2s ease;
    margin: 0;
  }

  ${props => props.collapsed && css`
    max-height: 50px;

    picture > img {
      height: 50px !important;
      object-fit: contain !important;
    }
  `}
`;

export const Navigation = styled.div`
  margin-left: auto;
  list-style-type: none;

  a, button {
    color: unset;
    padding-bottom: 2px;
    border: none;
    outline: none;
    background: #fff;

    svg {
      fill: #000;
      vertical-align: text-top;
    }

    &:hover,
    &.active {
      color: ${props => props.theme.brand_color};

      svg {
        fill: ${props => props.theme.brand_color};
      }

        //border-bottom: 2px solid ${props => props.theme.brand_color};
    }

    &.current {
      color: ${props => props.theme.brand_color};
      font-weight: bold;
      border-bottom: 3px solid ${props => props.theme.brand_color};
    }

    &.button.current {
      color: ${props => props.theme.base_color};
      border-bottom-width: 2px;
    }
  }

  .subnav {
    display: none;
    position: absolute;
    list-style-type: none;
    flex-direction: column;
    background-color: #fff;
    margin: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    li {
      padding: 12px 20px;
      margin: 0;
    }
  }

  .button {
    background-color: ${props => props.theme.brand_color};
    border: 2px solid ${props => props.theme.brand_color};
    color: ${props => props.theme.base_color};
    padding: 5px 8px;
    border-radius: 3px;
    transition: background-color .1s;

    &.current {
      font-weight: normal;
    }

    &:hover {
      background-color: #fff;
      color: ${props => props.theme.brand_color} !important;
    }
  }

  > ul {
    display: flex;
    margin: 0;
    padding: 0;

    > li {
      list-style-type: none;
      padding: 0 1rem;
      margin: 5px 0;

      &:hover {
        .subnav {
          display: flex;
        }

        .int-groups {
          display: flex;
        }
      }
    }
  }
`;

export const MobileNavigation = styled.div`
  background-color: ${props => props.theme.base_color};
  border-bottom: 5px solid ${props => props.theme.brand_color};
  padding: 20px 30px;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      border-bottom: 1px solid lightgray;
    }

    li {
      margin: 0;

      a, button {
        outline: none;
        border: none;
        color: unset;
        background: unset;
        padding: 10px 20px;
        display: block;
        height: 100%;
        width: 100%;
        text-align: left;

        &.current {
          color: ${props => props.theme.brand_color};
          font-weight: bold;
        }

        svg {
          fill: #000;
          vertical-align: text-top;
        }
      }
    }
  }

  .subnav {
    margin-left: 20px;
  }

  .int-groups-inline {
    margin-left: 20px;

    .int-group-inline {
      button {
        font-weight: bold;
      }

      .subnav {
      }
    }
  }
`;

export const MenuToggler = styled.div`
  margin-left: 20px;
`;

export const Profile = styled.div`
  flex-shrink: 1;
  margin-left: 20px;
  position: relative;
  text-align: left;
  height: 30px;

  .int-icon-group {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background-color: unset;
  }

  .int-user {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    color: ${props => props.theme.brand_color};
  }

  ul {
    position: absolute;
    top: 30px;
    right: 0;
    display: ${props => props.menuOpen ? 'block' : 'none'};
    border-bottom: 2px solid ${props => props.theme.brand_color};
    box-shadow: 0 10px 11px rgba(0, 0, 0, 0.2);
    list-style-type: none;
    margin: 0;

    li {
      background-color: #fff;
      margin: 0;
      padding: 0;

      &:hover {
        background-color: #cccccc;
      }

      a, button {
        display: block;
        width: 100%;
        height: 100%;
        color: unset;
        padding: 10px 20px;
        white-space: nowrap;
      }

      button {
        border: none;
        outline: none;
        background-color: unset;
        cursor: pointer;
        text-align: left;
      }
    }
  }
`;
