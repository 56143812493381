import React from 'react';

import Seminar from './Seminar';

const Seminars = ({ seminars }) => {
  let publishedSeminars = [];
  if (seminars.length > 0) {
    publishedSeminars = seminars.filter(seminar => seminar && seminar.status === 'published');
  }

  return (<>
      {publishedSeminars.length > 0 && publishedSeminars.map(seminar => <Seminar key={seminar.id}
                                                                                 seminarData={seminar}/>)}
      {publishedSeminars.length === 0 && <div className='int-seminars-empty'><p>Termine in Vorbereitung</p></div>}
    </>
  )
};

export default Seminars;