import React      from 'react';
import { Router } from '@reach/router';

import ArticleTemplate  from '../templates/blog/Article';
import CategoryTemplate from '../templates/blog/Category';
import ArticlesTemplate from '../templates/blog/Articles';
import NotFound         from '../components/NotFound';

const Blog = () => {
  return (
    <Router primary={false}>
      <ArticlesTemplate path={'/aktuelles/'}/>
      <CategoryTemplate path={'/aktuelles/kategorie/:category/'}/>
      <ArticleTemplate path={'/aktuelles/:category/:slug'}/>
      <NotFound default/>
    </Router>
  )
};

export default Blog;
