import React from 'react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Card               from '../Card/Card';
import { SeminarDetails } from './styles';
import MoreExpander       from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';

const Seminar = ({ seminarData: seminar }) => {
  return (
    <>
      <Card>
        {seminar.date && <i style={{ color: 'gray' }}>{seminar.date}</i>}
        <Spacer height={10}/>
        <h3 style={{ fontWeight: 'bold' }}>{seminar.title}</h3>
        <MoreExpander title={'Seminardetails'}>
          <SeminarDetails>
            {seminar.region && <li><b>Region:</b> <span>{seminar.region.description}</span></li>}
            {seminar.registration && <li><b>Anmeldung:</b> <span>{seminar.registration}</span></li>}
            {seminar.speaker && <li><b>Referent/in:</b> <span>{seminar.speaker}</span></li>}
            {seminar.venue && <li><b>Ort:</b> <span>{seminar.venue}</span></li>}
            {seminar.cost && <li><b>Kosten:</b> <span>{seminar.cost}</span></li>}
          </SeminarDetails>
        </MoreExpander>
      </Card>
      <Spacer height={20}/>
    </>
  )
};

export default Seminar;