import React, { useState, useContext, useEffect } from 'react';
import { navigate }                               from '@reach/router';

import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Button                        from '@interness/web-core/src/components/elements/Button/Button';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import { ApiContext }                from '@interness/users-addon/src/context/Api';
import Separator                     from '@interness/web-core/src/components/structure/Separator/Separator';
import { GlobalContext }             from '@interness/users-addon/src/context/Global';

const ArticleTemplate = (props) => {
  const { client, isLoggedIn } = useContext(ApiContext);
  const { setPathBeforeRedirect } = useContext(GlobalContext);

  const [articleRequest, setArticleRequest] = useState({
    article: null,
    loading: false,
    showContent: true,
  });
  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    const fetchArticle = async () => {
      setArticleRequest({ loading: true });
      if (!articleRequest.article) {
        const article = await client.getItems('article', {
          'filter[slug]': props.slug,
          fields: '*.*.*.*',
          single: 1
        });
        setArticleRequest({
          loading: false,
          article: article.data,
          showContent: article.data.status === 'internal' ? isLoggedIn : true,
        });
      }
    };

    fetchArticle();
    //eslint-disable-next-line
  }, [isLoggedIn]);

  const { loading, article, showContent } = articleRequest;

  if (!hasMounted) {
    return null;
  }

  const onReadMore = async () => {
    const articlePath = props.location.pathname;
    await setPathBeforeRedirect(articlePath);
    navigate(`/mitgliederbereich`);
  }

  return (
    <Wrapper>
      <Spacer/>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {article &&
      <article>
        <Heading subtitle={article.category.title}>{article.title}</Heading>
        {showContent && <div dangerouslySetInnerHTML={{ __html: article.content }}/>}
        {!showContent &&
        <div>
          <div dangerouslySetInnerHTML={{ __html: article.public_excerpt }}/>
          <Spacer/>
          <div style={{ textAlign: 'center' }}>
            <Button primary onClick={onReadMore}>Anmelden zum weiterlesen</Button>
          </div>
        </div>
        }
        {showContent && article.attachments.length > 0 && <div>
          <Separator/>
          <h4 style={{ fontWeight: 'bold' }}>Anhänge</h4>
          <ul>
            {article.attachments.map(attachment => <li key={attachment.id}>
              <Link external to={attachment.file.data.full_url}>{attachment.file.title}</Link>
            </li>)}
          </ul>
        </div>}
      </article>}
      <Spacer/>
    </Wrapper>
  )
};

export default ArticleTemplate;