import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Satzung`}</h1>
      <h2 {...{
        "id": "-i-name-und-sitz"
      }}>{`§ I Name und Sitz`}</h2>
      <ol>
        <li parentName="ol">{`Der Berufsverband FVDH ist ein gemeinnütziger und nach § 21 ff. BGB eingetragener Verein.`}</li>
        <li parentName="ol">{`Der Verein ist in das Vereinsregister des Amtsgerichtes Münster eingetragen.`}</li>
        <li parentName="ol">{`Der Sitz des Vereins ist Münster.`}</li>
      </ol>
      <h2 {...{
        "id": "-ii-zweck-des-vereins"
      }}>{`§ II Zweck des Vereins`}</h2>
      <ol>
        <li parentName="ol">{`Der FVDH ist eine berufsständische Vereinigung von Heilpraktikern und Heilpraktiker-Anwärtern. Er ist politisch und in Bezug auf die jeweils herrschenden naturheilkundlichen Schulmeinungen und Strömungen ungebunden.`}</li>
        <li parentName="ol">{`Der FVDH arbeitet mit anderen berufsständischen Verbänden zusammen und vertritt die Interessen der Mitglieder gegenüber den Behörden und gesetzgebenden Körperschaften sowie in der Öffentlichkeit.`}</li>
        <li parentName="ol">{`Der FVDH unterstützt und fördert seine Mitglieder und deren berufliche Interessen und berät sie in berufsständischen Fragen. Der FVDH fördert insbesondere den lauteren Wettbewerb und bekämpft unlauteren Wettbewerb sowie andere Missstände und schädigende Auswüchse im geschäftlichen Verkehr, erforderlichenfalls im Zusammenwirken mit den zuständigen Organen der Rechtspflege. Hierzu gehört auch die Überwachung der Einhaltung der heilkundlichen Lauterkeitsvorschriften wie dem Heilpraktikergesetz, dem Heilmittelwerbegesetz sowie des Arzneimittelgesetzes sowie die Verfolgung von Verstößen hiergegen.`}</li>
        <li parentName="ol">{`Der FVDH fördert den Nachwuchs des Heilpraktikerstandes und stellt bei Bedarf fachkundige Prüfungsbeisitzer für seine Mitglieder.`}</li>
        <li parentName="ol">{`Der FVDH sieht es als seine Aufgabe, das Bild des Berufsstandes in der Öffentlichkeit mit geeigneten Maßnahmen im positiven Sinne zu beeinflussen sowie in der Öffentlichkeit die Prinzipien naturheilkundlicher Lebensführung und Heilwesen durch geeignete Aufklärungsmaßnahmen zu verankern.`}</li>
        <li parentName="ol">{`Die Mitglieder haben das Recht, das Vereinssignum zu führen. Diejenigen Mitglieder, die die Eröffnung einer eigenen Praxis melden, erhalten vom Verband gegen Entrichtung der Selbstkosten Verbandsstempel, Mitgliedsnummer, ein Zusatzschild zur Praxiseröffnung, eine Gebühren- und Berufsordnung. Die Mitglieder erhalten einen Mitgliedsausweis.`}</li>
      </ol>
      <h2 {...{
        "id": "-iii-mitgliedschaft"
      }}>{`§ III Mitgliedschaft`}</h2>
      <ol>
        <li parentName="ol">{`Als ordentliches Mitglied kann durch Vorstandsbeschluß aufgenommen werden:
a) jeder Bürger der Bundesrepublik Deutschland, der die gesetzlich vorgeschriebene Zulassung als Heilpraktiker hat;
b) jeder Bürger eines ausländischen Staates, der in seinem Land die Zulassung als Heilpraktiker hat oder aufgrund seiner Ausbildung die fachliche Qualifikation eines Heilpraktikers in der Bundesrepublik erfüllt;
c) jeder Bürger in der Bundesrepublik Deutschland, der sich auf den Beruf des Heilpraktikers vorbereitet;
d) jeder Bürger in der Bundesrepublik Deutschland, der dem naturheilkundlichen Gedanken nahesteht oder einem Heilberuf angehört.`}</li>
        <li parentName="ol">{`Die ordentlichen Mitglieder unterstellen sich der Fachaufsicht des Berufsverbandes und erteilen dem Vorstand und dem Bundespräsidium auf Verlangen berufsbezogene Auskünfte.`}</li>
        <li parentName="ol">{`Als fördernde Mitglieder können durch Vorstandsbeschluß alle natürlichen oder juristischen Personen aufgenommen werden, die:
a) dem Berufsverband des Heilpraktikers durch Mitwirkung an qualifizierter Aus-, Fort- und Weiterbildung verbunden sind;
b) an der Förderung der Naturheilkunde interessiert sind;
c) an der Außenvertretung des FVDH im Auftrag des Vorstandes mitwirken.`}</li>
        <li parentName="ol">{`Die Präsidien können dem Vorstand Anträge auf Ehrenmitgliedschaft zur Abstimmung vorlegen. Ehrenmitglieder bleiben beitragsfrei.`}</li>
        <li parentName="ol">{`Bei korporativer Mitgliedschaft gelten die Einzelmitglieder der Korporation als ordentliche Mitglieder des FVDH. Es kann bei korporativer Mitgliedschaft vom Vorstand Beitragsfreiheit beschlossen werden.`}</li>
      </ol>
      <h2 {...{
        "id": "-iv-beendigung-der-mitgliedschaft"
      }}>{`§ IV Beendigung der Mitgliedschaft`}</h2>
      <ol>
        <li parentName="ol">{`Die Mitgliedschaft endet:
a) durch die schriftliche Austrittserklärung des Mitgliedes unter Einhaltung einer Frist von 3 Monaten zum Ende eines Kalenderjahres;
b) durch Ausschluss.`}</li>
        <li parentName="ol">{`Über den Ausschluss eines Mitgliedes bestimmt der Vorstand, wenn:
a) die Interessen des FVDH grob verletzt wurden;
b) das Ansehen des Berufsstandes geschädigt wurde;
c) bei nachhaltigem Rückstand des fälligen Beitrages.
Vor dem Ausschlussverfahren erhält das Mitglied eine Frist von 4 Wochen, innerhalb der es sich schriftlich zum Ausschlussverfahren äußern kann. Ein Anspruch des ausgeschiedenen oder ausgeschlossenen Mitgliedes am Vereinsvermögen besteht nicht.`}</li>
      </ol>
      <h2 {...{
        "id": "-v-mitgliederbeitrag"
      }}>{`§ V Mitgliederbeitrag`}</h2>
      <ol>
        <li parentName="ol">{`Die Mitglieder zahlen eine Jahresbeitrag, der im voraus halbjährlich oder jährlich entrichtet wird.`}</li>
        <li parentName="ol">{`Neu aufgenommene Mitglieder, die in einem anderen Berufsverband der Heilpraktiker Mitglied sind, zahlen für die Zeit der Doppelmitgliedschaft keinen Beitrag, wenn sie ein Kündigungsschreiben zum nächsten ordentlichen Kündigungstermin vorlegen.`}</li>
        <li parentName="ol">{`Die Höhe des Jahresbeitrages wird von der Mitgliederversammlung auf Vorschlag des Vorstandes festgesetzt.`}</li>
        <li parentName="ol">{`Der Vorstand kann für Zwecke der Mitgliederwerbung beitragsfreie Zeiten bestimmen, Beitragserlasse sowie ermäßigte Beiträge für HPA und Sondergruppen festsetzen.`}</li>
        <li parentName="ol">{`Erzwingen außerordentliche Umstände eine Änderung der Höhe des Mitgliedsbeitrages, so kann der Vorstand und die Präsidien hierüber befinden.`}</li>
      </ol>
      <h3 {...{
        "id": "-vi-organe-des-fvdh"
      }}>{`§ VI Organe des FVDH`}</h3>
      <h4 {...{
        "id": "die-organe-des-fvdh-sind"
      }}>{`Die Organe des FVDH sind:`}</h4>
      <ol>
        <li parentName="ol">{`Der Vorstand`}</li>
        <li parentName="ol">{`Der Beirat für Ausbildung`}</li>
        <li parentName="ol">{`Die Fachbeiräte`}</li>
        <li parentName="ol">{`Das Bundespräsidium`}</li>
        <li parentName="ol">{`Die Landespräsidien`}</li>
        <li parentName="ol">{`Die Mitgliederversammlung`}</li>
        <li parentName="ol">{`Die Landesleiter.`}</li>
      </ol>
      <h2 {...{
        "id": "-vii-der-vorstand"
      }}>{`§ VII Der Vorstand`}</h2>
      <ol>
        <li parentName="ol">{`Der Vorstand wird von der Mitgliederversammlung auf die Dauer von 7 Jahren mit einfacher Mehrheit gewählt.`}</li>
        <li parentName="ol">{`Der Vorstand wird gebildet aus zwei gleichberechtigten Mitgliedern.`}</li>
        <li parentName="ol">{`Der Vorstand führt die Geschäfte des FVDH, soweit sie nicht anderen Organen übertragen sind. Er unterrichtet die Präsidien und Mitglieder sowie die Beiräte regelmäßig. Er kann einen oder mehrere Geschäftsführer bestellen.`}</li>
        <li parentName="ol">{`Mindestens ein Vorstandsmitglied muß amtsärztlich zugelassener Heilpraktiker sein.`}</li>
        <li parentName="ol">{`Die Tätigkeit des Vorstandes ist ehrenamtlich. Die Aufwandsentschädigung orientiert sich an den Realunkosten und wird vom Bundespräsidium festgesetzt.`}</li>
        <li parentName="ol">{`Scheidet ein Vorstandsmitglied aus, so übernimmt der verbleibende Vorstand bis zur nächsten ordentlichen Mitgliederversammlung die Aufgaben des zurückgetretenen Vorstandsmitgliedes kommissarisch.`}</li>
        <li parentName="ol">{`Tritt der Vorstand zurück, so bestimmt das Bundespräsidium über die kommissarische Besetzung des Vorstandes bis zur nächsten ordentlichen Mitgliederversammlung.`}</li>
        <li parentName="ol">{`Der Vorstand ist berechtigt, im Namen des Vereins alle Handlungen vorzunehmen, die zur Bekämpfung unlauteren Wettbewerbes auf dem Gebiet des Berufsstandes der Heilpraktiker im Sinne des Vereinszweckes erforderlich sind. Er ist berechtigt, in diesem Sinne unlauteres Verhalten abzumahnen, Zivilprozesse zu führen, Vergleiche abzuschließen und strafrechtliche Schritte einzuleiten.`}</li>
      </ol>
      <h2 {...{
        "id": "-viii-das-bundesprasidium"
      }}>{`§ VIII Das Bundespräsidium`}</h2>
      <p>{`Das Bundespräsidium wird vom Vorstand aus den Reihen fachlich und persönlich qualifizierter Mitglieder berufen. Ihm gehören mindestens drei, höchstens sieben Mitglieder an. Das Bundespräsidium bereitet alle wesentlichen Entscheidungen des Vorstandes beratend vor und erfüllt notwendige bundesweite Repräsentation- und Koordinationsaufgaben im Auftrag des Vorstandes, der auch die Geschäftsordnung des Präsidiums festlegt.
Innerhalb des Präsidiums wird mit einfacher Mehrheit abgestimmt. Aufwendungen, die entschädigungspflichtig werden, sind bei Vorstand zu beantragen und vom Vorstand schriftlich zu genehmigen. Der Vorstand kann auch ein Budget festsetzen.`}</p>
      <h2 {...{
        "id": "-ix-die-landesprasidien"
      }}>{`§ IX Die Landespräsidien`}</h2>
      <p>{`Entsprechend den Funktionen des Bundespräsidiums werden auf Landesebene Landespräsidien für Aufgaben eingesetzt, die auf regionaler Ebene von Bedeutung sind. Die Landespräsidien referieren direkt dem Vorstand. Der Vorstand entscheidet über die Zusammensetzung der Landespräsidien.`}</p>
      <h2 {...{
        "id": "-x-die-beirate"
      }}>{`§ X Die Beiräte`}</h2>
      <p>{`Der Vorstand setzt aus fachlich und persönlich qualifizierten Mitgliedern und Nichtmitgliedern Beiräte zur Entscheidungsvorbereitung, Information von Vorstand, Präsidien und Mitgliedern sowie für besondere Sachaufgaben ein.`}</p>
      <p>{`Der Beirat für Aus- und Fortbildungsfragen: Er wird gestellt vom offiziellen Ausbildungsorgan des Vereins. Er genießt in Aus- und Fortbildungsfragen Vorstandskompetenz. In Aus- und Fortbildungsfragen kann der Beirat bei entsprechenden Vorstandsbeschlüssen ein Vetorecht wahrnehmen.`}</p>
      <h2 {...{
        "id": "-xi-die-mitgliederversammlung"
      }}>{`§ XI Die Mitgliederversammlung`}</h2>
      <ol>
        <li parentName="ol">{`Die Mitgliederversammlung findet in der Regel alle 2 Jahre statt. Die Einladung erfolgt durch Mitteilung in der Verbandszeitschrift oder durch schriftliche Einladung mindestens 4 Wochen vor dem Versammlungstermin.`}</li>
        <li parentName="ol">{`Eine außerordentliche Mitgliederversammlung findet statt, wenn der Vorstand sie einberuft oder wenn mindestens 49% der Mitglieder die Einberufung schriftlich vom Vorstand verlangen.`}</li>
        <li parentName="ol">{`Die ordentliche Mitgliederversammlung hat vor allem die Aufgaben:
a) Genehmigung des Protokolls der letzten Versammlung; b) Entgegennahme des Berichts des Vorstandes; c) Entgegennahme des Berichts des Schrift- und Rechnungsführers; d) Entgegennahme des Berichts der Rechnungsprüfer; e) Abstimmung über Entlastung; f) Wahlen; g) Festsetzung der Mitgliedsbeiträge.
Sonstige Tagesordnungspunkte werden aufgenommen, wenn sie spätestens 2 Wochen vor dem Versammlungstermin schriftlich beim Vorstand gemeldet worden sind oder von der Mitgliederversammlung mehrheitlich auf die Tagesordnung gesetzt werden.`}</li>
        <li parentName="ol">{`Die ordentliche Mitgliederversammlung wählt den Vorstand mit einfacher Stimmenmehrheit, das heißt: Mit einer Stimme mehr als die Hälfte der abgegebenen Stimmen. Die Mitgliederversammlung wählt einen Rechnungsprüfer, der nicht dem Vorstand angehört, und der seinen Bericht über die Rechnungs- und Kassenprüfung mündlich vorträgt und dem Schriftführer als Anlage zu dem Protokoll schriftlich übergibt.`}</li>
        <li parentName="ol">{`Die ordnungsgemäß einberufene Mitgliederversammlung ist beschlussfähig.`}</li>
        <li parentName="ol">{`In der Regel beschließt die Mitgliederversammlung per Handzeichen. Die Mitgliederversammlung kann durch Abstimmung jedes andere zulässige Abstimmungsverfahren beschließen. Die Wahl des Vorstandes erfolgt durch Stimmzettel.`}</li>
        <li parentName="ol">{`Ein Beschluß, der eine Änderung der Satzung enthält, bedarf der Zustimmung von drei Vierteln der anwesenden Mitglieder. Der Vorstand kann Satzungsänderungen beschließen, wenn sie zum vereinsregisterlichen Eintrag notwendig werden.`}</li>
        <li parentName="ol">{`Anträge auf Änderung der Satzung müssen den Mitgliedern 8 Wochen vor dem Versammlungstermin schriftlich bekanntgegeben werden.`}</li>
        <li parentName="ol">{`Sämtliche Protokolle der Mitgliederversammlung sind von einem Vorstandsmitglied oder einem vom Vorstand bestimmten Schriftführer zu unterzeichnen.`}</li>
      </ol>
      <h2 {...{
        "id": "-xii-auflosung-des-vereins"
      }}>{`§ XII Auflösung des Vereins`}</h2>
      <p>{`Bei Auflösung des Vereins fällt das Verbandsvermögen an das SOS-Kinderdorf.`}</p>
      <p><strong parentName="p">{`Münster, den 10.09.2011`}</strong></p>
      <p>{`Der Vorstand`}</p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      