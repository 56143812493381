import styled from '@emotion/styled';

export const SeminarDetails = styled.ul`
  list-style-type: none;
  margin: 0;
  li {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px 0;
    b {
      min-width: 120px;
      margin-right: 20px;
      flex-grow: 0;
    }
  }
`;