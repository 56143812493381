import React, { useContext, useState, useEffect } from 'react';
import HamburgerMenu                              from 'react-hamburger-menu';
import { Breakpoint }                             from 'react-socks';
import Portal                                     from '@reach/portal';
import AnimateHeight                              from 'react-animate-height';

import { useHeaderData } from '@interness/web-core/src/hooks';
import Logo              from '@interness/web-core/src/components/media/Logo/Logo';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import MenuStructure     from '@interness/web-core/src/components/structure/MenuStructure/MenuStructure';
import { GlobalContext } from '@interness/web-core/src/providers';
import { ApiContext }    from '@interness/users-addon/src/context/Api';

import * as S          from './styles';
import { membersMenu } from './membersMenu';
import Profile         from './Profile';

const Header = () => {
  const { isLoggedIn, handleLogout, currentUser } = useContext(ApiContext);
  const { primaryMenuOpen, togglePrimaryMenu } = useContext(GlobalContext);
  const data = useHeaderData();
  const [currentMenu, setCurrentMenu] = useState(data.menu);

  const toggleMenu = () => togglePrimaryMenu(!primaryMenuOpen);

  const unauthMenu = [...data.menu];
  const authMenu = [...data.menu];
  authMenu.pop();
  authMenu.push(membersMenu);

  useEffect(() => {
      if (isLoggedIn) {
        setCurrentMenu(authMenu);
      } else {
        setCurrentMenu(unauthMenu);
      }
    }, //eslint-disable-next-line
    [isLoggedIn]);

  return (
    <S.Header>
      <S.Wrapper>
        <S.Logo maxWidth={data.logo.localFile.childImageSharp.original.width}>
          <Link to='/'>
            <Logo logo={data.logo.localFile} title={data.seo_title}/>
          </Link>
        </S.Logo>
        <Breakpoint large up style={{ marginLeft: 'auto' }}>
          <S.Navigation>
            <MenuStructure menu={currentMenu}/>
          </S.Navigation>
        </Breakpoint>
        <Breakpoint medium down style={{ marginLeft: 'auto' }}>
          <S.MenuToggler>
            <HamburgerMenu isOpen={primaryMenuOpen} menuClicked={toggleMenu} strokeWidth={3} borderRadius={5}/>
            <Portal>
              <AnimateHeight duration={500} height={primaryMenuOpen ? 'auto' : 0}
                             style={{ position: 'absolute', top: '170px', left: 0, right: 0, zIndex: 1 }}>
                <S.MobileNavigation>
                  <MenuStructure menu={currentMenu} renderGroupsInPortal={false}/>
                </S.MobileNavigation>
              </AnimateHeight>
            </Portal>
          </S.MenuToggler>
        </Breakpoint>
        {isLoggedIn && currentUser && <Profile currentUser={currentUser} handleLogout={handleLogout}/>}
      </S.Wrapper>
    </S.Header>
  )
};

export default Header;
