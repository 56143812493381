import React from 'react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import useClient                     from '@interness/users-addon/src/hooks/useClient';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import Seminars from '../../components/Seminars/Seminars';
import Error    from '../../components/Error/Error';

const Seminare = () => {

  const params = {
    status: 'published',
    sort: 'begin_date',
    fields: '*.*'
  };

  let { loading, data: seminars, error } = useClient('seminar', params, null, true);

  return (
    <Wrapper>
      <Spacer/>
      <Heading>Seminare</Heading>
      {error && <Error message='Seminare konnten nicht gefunden werden'/>}
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {seminars && <Seminars seminars={seminars}/>}
    </Wrapper>
  )
};

export default Seminare;
