import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Roles from '../../components/Roles/Roles';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Fachfortbildungsleiter/in`}</h1>
      <h2 {...{
        "id": "bundesfachfortbildungsleiter"
      }}>{`Bundesfachfortbildungsleiter`}</h2>
      <Roles role_name='Bundesfachfortbildungsleiter' mdxType="Roles" />
      <h2 {...{
        "id": "fachfortbildungsleiter"
      }}>{`Fachfortbildungsleiter`}</h2>
      <Roles role_name='Fachfortbildungsleiter' mdxType="Roles" />
      <h2 {...{
        "id": "fachfortbildungsleiterin-fur-psychotherapiepsychologie"
      }}>{`Fachfortbildungsleiterin für Psychotherapie/Psychologie`}</h2>
      <Roles role_name='Fachfortbildungsleiter-Psychotherapie' mdxType="Roles" />
      <h2 {...{
        "id": "ausbildungs--und-fachfortbildungsleiterin-fur-osteopathie"
      }}>{`Ausbildungs- und Fachfortbildungsleiterin für Osteopathie`}</h2>
      <Roles role_name='Fachfortbildungsleiter-Osteopathie' mdxType="Roles" />
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      