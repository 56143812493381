import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Button  from '@interness/web-core/src/components/elements/Button/Button';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Link    from '@interness/web-core/src/components/elements/Link/Link';


const BehandlungsvertragADT = () => {
  const data = useStaticQuery(query);
  return (
    <Wrapper>
      <Spacer/>
      <Heading>Behandlungsvertrag</Heading>
      <p>
        Laden Sie hier einen Behandlungsvertrag für die Verwendung in Ihrer Praxis herunter.
      </p>
      <div style={{ textAlign: 'center' }}>
        <Button external as={Link} to={data.pdf.media[0].file.localFile.publicURL}>Behandlungsvertrag</Button>
      </div>

    </Wrapper>
  )
};

const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "adt-behandlungsvertrag"}) {
            media {
                file {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`;
export default BehandlungsvertragADT;

