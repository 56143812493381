export const membersMenu = {
  displayName: 'Mitgliederbereich',
  type: 'button',
  groups: [
    {
      displayName: 'Mitgliederservice',
      sub: [
        {
          displayName: 'Mitgliederinformationen',
          path: '/mitgliederbereich/home'
        },
        {
          displayName: 'Arzneimittelkommision (AMK)',
          path: '/mitgliederbereich/arzneimittelkommision'
        },
        {
          displayName: 'Hotline',
          path: '/mitgliederbereich/hotline'
        },
        {
          displayName: 'Rechtsberatung',
          path: '/mitgliederbereich/rechtsberatung'
        },
        {
          displayName: 'Produkte',
          path: '/mitgliederbereich/produkte'
        },
        {
          displayName: 'Presse',
          path: '/mitgliederbereich/presse'
        }
      ]
    },
    {
      displayName: 'AKOM Online',
      sub: [
        {
          displayName: 'Aktuelles AKOM Online',
          path: '/aktuelles/aktuelle-fvdh-news/akom-online'
        }
      ]
    },
    {
      displayName: 'Mitgliederservice (ADT)',
      sub: [
        {
          displayName: 'Mitgliederinformationen (ADT)',
          path: '/mitgliederbereich/home-adt'
        },
        {
          displayName: 'Gebührenverzeichnis (ADT)',
          path: '/mitgliederbereich/gebuehrenverzeichnis-adt'
        },
        {
          displayName: 'Behandlungsvertrag (ADT)',
          path: '/mitgliederbereich/behandlungsvertrag-adt'
        },
        {
          displayName: 'Berufsordnung (ADT)',
          path: '/mitgliederbereich/berufsordnung-adt'
        }
      ]
    },
  ]
};