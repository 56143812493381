import React from 'react';

import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

import Articles from '../../components/Articles/Articles';

const ArticlesTemplate = ({ page }) => {
  return (
    <div>
      <Wrapper>
        <Spacer/>
        <Heading>Alle Beiträge</Heading>
        <Spacer height={20}/>
        <Articles/>
        <Spacer/>
      </Wrapper>
    </div>
  )
};

export default ArticlesTemplate;
