import React, { useContext } from 'react';
import { FormattedDate }     from 'react-intl';
import { navigate }          from '@reach/router';
import ReactTooltip          from 'react-tooltip';
import {
  FaLock,
  FaArrowRight,
  FaUserCircle,
  FaClock,
  FaTag
}                            from 'react-icons/fa';

import { GlobalContext } from '@interness/users-addon/src/context/Global';

import * as S from './styles';
import Card   from '../Card/Card';

const Article = ({ data, isLoggedIn }) => {
  const { setPathBeforeRedirect } = useContext(GlobalContext);

  const onReadMore = async () => {
    const articlePath = `/aktuelles/${data.category.slug}/${data.slug}`;
    await setPathBeforeRedirect(articlePath);
    navigate(`/mitgliederbereich`);
  }
  return (
    <Card style={{ marginBottom: '50px' }}>
      <S.Header>
        <h3>
          {data.status === 'internal' &&
          <span className='int-internal-indicator' data-tip='Beitrag für FVDH Mitglieder'>
            <FaUserCircle/>
            <ReactTooltip/>
          </span>
          }
          {data.title}
        </h3>
        <div className='int-article-meta'>
          <div className='int-article-date'>
            <FaClock/>
            <FormattedDate value={data.published_date}
                           hour12={false}
                           year='numeric'
                           month='long'
                           day='2-digit'/>
          </div>
          <div className='int-article-category' role='navigation'>
            {/*eslint-disable-next-line*/}
            <span role='link'
                  onKeyDown={() => navigate(`/aktuelles/kategorie/${data.category.slug}`)}
                  onClick={() => navigate(`/aktuelles/kategorie/${data.category.slug}`)}><FaTag/> {data.category.title}</span>
          </div>
        </div>
      </S.Header>
      {(data.public_excerpt || data.content) && <S.Separator/>}
      <div style={{ marginTop: '20px' }}>
        {data.public_excerpt
          ? <div dangerouslySetInnerHTML={{ __html: data.public_excerpt }}/>
          : <div dangerouslySetInnerHTML={{ __html: data.content }}/>
        }
      </div>
      {data.public_excerpt && <div style={{ textAlign: 'right' }}>
        {data.status === 'published' || isLoggedIn
          ? <S.ReadMore
            onClick={() => navigate(`/aktuelles/${data.category.slug}/${data.slug}`)}>Weiterlesen <FaArrowRight
            style={{ verticalAlign: 'middle' }}/></S.ReadMore>
          : <S.ReadMore onClick={onReadMore}>Weiterlesen <FaLock
            style={{ verticalAlign: 'text-top' }}/></S.ReadMore>
        }
      </div>}
    </Card>
  )
};

export default Article;