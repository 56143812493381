import styled from '@emotion/styled';

const Card = styled.div`
  background-color: rgb(255, 254, 237);
  border-bottom: 5px solid ${props => props.theme.brand_color};
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  padding: 30px 45px;
  transition: box-shadow 0.1s;
  button {
    background-color: rgb(255, 254, 237) !important;
  }
  &:hover {
    box-shadow: 0 10px 25px rgba(0,0,0,0.1);
  }
`;

export default Card;