import React                            from 'react';
import { GoX }                          from 'react-icons/go';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';

import MoreExpander from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Button       from '@interness/web-core/src/components/elements/Button/Button';

import { dialogOverlay, dialogContent, CloseButton, DialogHeader, Inner, Details } from './styles';
import MemberProfile
                                                                                   from '../MemberProfile/MemberProfile';


const MemberSearchDialog = ({ memberList, close, zip, region, title }) => {
  return (
    <DialogOverlay onDismiss={close} css={dialogOverlay}>
      <DialogContent css={dialogContent} aria-label='Suche Ergebnisse'>
        <DialogHeader>
          <h3>{memberList.length} {title} in {zip ? zip : region.description} gefunden</h3>
          <CloseButton aria-label='Schließen' className='int-btn-close' onClick={(e) => {
            close(e)
          }}><GoX/></CloseButton>
        </DialogHeader>
        <Inner>
          {memberList.length === 0 && <div style={{textAlign: 'center'}}>
            <Button primary onClick={() => close()}>Schließen</Button>
          </div>}
          {memberList.length > 0 && <ul style={{ listStyleType: 'none' }}>
            {memberList.map(member => (
              <li key={member.id}>
                <MoreExpander title={`${member.last_name}, ${member.first_name} - ${member.zip}`}
                              separatorColor={'#cacaca'}>
                  <Spacer height={20}/>
                  <Details>
                    <div>
                      <MemberProfile memberData={member} portrait={false}/>
                    </div>
                    {(member.therapy_focus || member.therapy_further) && <div className='int-therapy-details'>
                      {member.therapy_focus && <>
                        <h4>Therapie Schwerpunkte</h4>
                        <p>{member.therapy_focus}</p>
                        <Spacer height={20}/>
                      </>}
                      {member.therapy_further && <>
                        <h4>Weitere Behandlungsmethoden</h4>
                        <p>{member.therapy_further}</p>
                      </>}
                    </div>}
                  </Details>
                  <Spacer height={20}/>
                </MoreExpander>
              </li>
            ))}
          </ul>}
        </Inner>
      </DialogContent>
    </DialogOverlay>
  )
};

export default MemberSearchDialog;