import React, { useContext, useState } from 'react';
import PropTypes                       from 'prop-types';
import { useFormState }                from 'react-use-form-state';
import { useCurrentWidth }             from 'react-socks';

import Button             from '@interness/web-core/src/components/elements/Button/Button';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import Expander           from '@interness/web-core/src/components/structure/Expander/Expander';
import useClient          from '@interness/users-addon/src/hooks/useClient';
import { ApiContext }     from '@interness/users-addon/src/context/Api';

import { Bar, Form }      from './styles';
import MemberSearchDialog from './MemberSearchDialog';

const MemberSearchBar = ({ adt }) => {
  const width = useCurrentWidth();
  const { client } = useContext(ApiContext);
  const [formState, { text, select }] = useFormState({});
  const { data: regions, loading: regionLoading } = useClient('region', null, null, true);
  const [memberList, setMemberList] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const texts = {
    hp: {
      bar: 'Heilpraktikersuche',
      dialog: 'Heilpraktiker'
    },
    adt: {
      bar: 'Tierheilpraktikersuche',
      dialog: 'Tierheilpraktiker'
    }
  };

  const setText = (id) => {
    return texts[adt ? 'adt' : 'hp'][id]
  };

  const fetchMembers = async () => {
    let params = {
      status: 'published',
      sort: 'last_name',
      filter: {
        include_in_search: 1,
        adt: adt ? '1' : '0'
      }
    };

    if (formState.values.zip) {
      if (!isNaN(formState.values.zip)) {
        params.filter['zip'] = formState.values.zip;
      } else {
        params.filter['place'] = formState.values.zip;
      }
    }

    if (formState.values.region) {
      params.filter['region'] = JSON.parse(formState.values.region).id;
    }

    const result = await client.getItems('member', params);

    await setMemberList(result.data);
  };

  const onSubmit = async e => {
    setClicked(true);
    e.preventDefault();
    await fetchMembers();
    await setShowDialog(true);
  };

  const onReset = () => {
    setClicked(false);
    setMemberList([]);
    formState.reset();
  };

  const close = () => {
    setShowDialog(false);
    onReset();
  };

  return (
    <Bar adt={adt}>
      {width > 960 && <h3>{setText('bar')}</h3>}
      <ConditionalWrapper condition={width < 960}
                          wrapper={children => <Expander title={setText('bar')}>{children}</Expander>}>
        <Form name='member-search'
              onSubmit={onSubmit}>
          <input {...text('zip')} placeholder='PLZ oder Ort'
                 disabled={formState.values.region && formState.values.region.length > 0}/>
          <p>oder</p>
          <select {...select('region')} disabled={regionLoading || (formState.values.zip && formState.values.zip.length > 0)}>
            <option value=''>Region auswählen</option>
            {regions && regions.filter(region => region.title !== 'Überregional').map(region => <option key={region.id}
                                                      value={JSON.stringify(region)}>{region.description}</option>)}
          </select>
          <Button type='submit' disabled={(!formState.validity.zip && formState.values.region === '') || clicked}>Suchen</Button>
        </Form>
      </ConditionalWrapper>
      {showDialog && <MemberSearchDialog memberList={memberList} close={close} zip={formState.values.zip}
                                         region={formState.values.region !== '' && JSON.parse(formState.values.region)}
                                         title={setText('dialog')}/>}
    </Bar>
  )
};

MemberSearchBar.propTypes = {
  adt: PropTypes.bool,
};

MemberSearchBar.defaultProps = {
  adt: false,
};

export default MemberSearchBar;