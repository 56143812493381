import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Zertifizierung`}</h1>
      <h2 {...{
        "id": "liebe-kolleginnen-liebe-kollegen-liebe-hp-anwarter"
      }}>{`Liebe Kolleginnen, liebe Kollegen, liebe HP-Anwärter,`}</h2>
      <p>{`Die Fachfortbildung gehört zum Pflichtprogramm jeder Kollegin und jedes Kollegen, denn das Sorgfaltspflichturteil des BGH verpflichtet uns zur ständigen fachlichen Weiterbildung und zu deren Nachweis. Durch enge Zusammenarbeit mit anderen Verbänden und der Pharmaindustrie haben wir in den letzten Jahren außerordentlich gute Erfahrungen gemacht. Dieses ist ein wichtiger Schritt für die Zukunftssicherung unseres Berufsstandes.`}</p>
      <h3 {...{
        "id": "zertifizierung-von-fach--und-weiterbildung-im-fvdh-e-v"
      }}>{`Zertifizierung von Fach- und Weiterbildung im FVDH e. V.`}</h3>
      <p><em parentName="p">{`Zertifizierung / Ausbildungsnachweis für Injektionstechniken, Neuraltherapie, Notfallmaßnahmen`}</em></p>
      <h4 {...{
        "id": "gesetzliche-rahmenbedingungen"
      }}>{`Gesetzliche Rahmenbedingungen:`}</h4>
      <p>{`Nach dem Grundsatzurteil des BGH Karlsruhe vom 29.01.1991 (BGH VI ZR 206/90) wird von jedem praktizierenden Kollegen/in verlangt, dass er in den Therapieformen, die er in der Praxis anwendet, einen entsprechenden Ausbildungsnachweis vorlegen kann und regelmäßig an Fortbildungen teilnimmt.`}</p>
      <h4 {...{
        "id": "interpretationauszug"
      }}>{`Interpretation/Auszug:`}</h4>
      <p>{`(„…sind Heilpraktiker verpflichtet, sich eine ausreichende Sachkunde…, vor allem die richtigen Techniken für deren gefahrlose Anwendung anzueignen. …auch verpflichtet, sich über die Fortschritte… von Nutzen und Risiken der von ihm angewendeten Heilverfahren zu informieren und umzusetzen”)`}</p>
      <p>{`Spätestens nach einem Therapiezwischenfall mit anhängigem Haftpflicht-Verfahren kann bei Nichtvorlage dieser Nachweise daraus schnell ein Strafverfahren mit drohender Praxisschließung werden!`}</p>
      <p>{`(Die Versicherung überprüft ihre Haftungsverpflichtung – liegt kein entsprechender Sachkunde-Nachweis vor, ist die Versicherung außen vor. Vom Geschädigten können dann schnell Tatbestände wie Fahrlässigkeit und Körperverletzung in die Diskussion gebracht werden…)`}</p>
      <p>{`Das bedeutet:`}</p>
      <p>{`wer invasive Therapieverfahren (Akupunktur, Injektionen, Infusionen) in der Praxis anwendet, muss nachweisen,`}</p>
      <ol>
        <li parentName="ol">{`dass er diese Verfahren gelernt hat,`}</li>
        <li parentName="ol">{`sich ständig fortbildet und`}</li>
        <li parentName="ol">{`eine Notfallausbildung mit jährlichem “Auffrischungskurs” gemacht hat.`}</li>
      </ol>
      <h4 {...{
        "id": "als-nachweis-reichen-nicht"
      }}>{`Als Nachweis reichen nicht:`}</h4>
      <ul>
        <li parentName="ul">{`Einführungskurse und Demos im Rahmen der HP-Ausbildung / Prüfungsvorbereitung,`}</li>
        <li parentName="ul">{`mehrjähriger „unfallfreier“ Praxisbetrieb,`}</li>
        <li parentName="ul">{`Erste-Hilfe-Kurse für Laien beim Roten Kreuz o.ä. Einrichtungen,`}</li>
        <li parentName="ul">{`Bescheinigungen von Ausbildern, Ärzten, Heilpraktikern…, die keine Lehrbefugnis haben`}</li>
        <li parentName="ul">{`(auch wenn sie fachlich qualifiziert sind, wie Notfallarzt, Rettungssanitäter usw. -`}</li>
        <li parentName="ul">{`speziell die Notfallmaßnahmen müssen „HP-spezifisch“ gelernt werden)`}</li>
      </ul>
      <p><strong parentName="p">{`Rechtlich relevante Ausbildungsnachweise können nur von autorisierten Lehrbeauftragen der Berufsverbände ausgestellt werden!`}</strong></p>
      <p>{`Der FVDH bietet über seine Lehrbeauftragten allen KollegInnen die Möglichkeit zum Erwerb dieser Nachweise / Zertifikate in Kursen für „alte Hasen“ und Einsteiger an.`}</p>
      <p>{`Nur durch die Ablage / Registrierung im Verbandsregister können die Rechtsabteilungen der Verbände bei versicherungstechnischen und rechtlichen Anfragen Unterstützung für ihre Mitglieder geben.`}</p>
      <p>{`Aufgrund der zu erwartenden neuen rechtlichen EU-Regelungen zum allgemeinen Patientenschutz können diese Ausbildungsnachweise schon bei Praxisbegehung durch die Gesundheitsämter zur Pflichtübung werden.`}</p>
      <p>{`Wir erinnern auch an die Eigenverantwortung eines jeden Therapeuten:`}</p>
      <p><strong parentName="p">{`Jeder Patient hat das Recht auf angemessene, risikoarme therapeutische Anwendungen durch Therapeuten, die ihr „Handwerk“ vernünftig erlernt haben und ihre Fähigkeiten gewissenhaft und professionell einsetzen.`}</strong></p>
      <p>{`Der Heilpraktiker hat sich der Grenzen seines Wissens und Könnens bewusst zu sein. Er ist verpflichtet, sich eine ausreichende Sachkunde über die von ihm angewandten Diagnose- und Behandlungsverfahren einschließlich ihrer Risiken, vor allem die richtigen Techniken für deren gefahrlosen Anwendung, anzueignen. (Zitat Berufsordnung)`}</p>
      <p><strong parentName="p">{`Siegfried Schierstedt`}</strong></p>
      <p>{`FVDH-Vorstand`}</p>
      <p><strong parentName="p">{`Matthias Mertler`}</strong></p>
      <p>{`FVDH-Vorstand`}</p>
      <p><strong parentName="p">{`Bernhard Deipenbrock`}</strong></p>
      <p>{`Bundesfachfortbildungsleiter FVDH`}</p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      