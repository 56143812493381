import React, { useState } from 'react';
import { navigate }        from 'gatsby';
import { useFormState }    from 'react-use-form-state';
import { Flex, Box }       from '@chakra-ui/react';

import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Button       from '@interness/web-core/src/components/elements/Button/Button';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Link         from '@interness/web-core/src/components/elements/Link/Link';
import toastHandler from '@interness/users-addon/src/helpers/toastHandler';

import { Form, Input } from './Login/styles';
import Spinner         from '@interness/web-core/src/components/modules/Spinner/Spinner';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [formState, { email, password, label, text }] = useFormState({}, {
    withIds: true,
    validateOnBlur: true,
  });

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (formState.values.email !== '' || formState.values.password !== '') {
      try {
        const res = await fetch(process.env.GATSBY_REGISTER_USER_ENDPOINT, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(formState.values)
        });
        await res.json();
        if (res.status === 200) {
          navigate('/mitgliederbereich');
          toastHandler('success', 'Sie können sich nun anmelden.', 'Erfolgreich registriert');
          setLoading(false);
        } else if (res.status === 409) {
          toastHandler('error', 'Sie sind bereits registriert. Bitte gehen Sie zur Anmeldung.', 'Bereits registriert.');
          setLoading(false);
        } else {
          toastHandler('error', 'Bitte überprüfen Sie Ihre Daten, insbesondere Mitgliedsnummer und Namen.', 'Registrierung fehlgeschlagen');
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        toastHandler('error', 'Bitte versuchen Sie es später erneut.', 'Registrierung fehlgeschlagen');
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Spacer/>
      <Heading subtitle='Mitgliederbereich'>Registrieren</Heading>
      <Form name='reset-password' onSubmit={onSubmit} width='640px'>
        <Flex flexWrap='wrap'>
          <Box width={['100%', '50%', '50%', '50%']}>
            <Input {...label('member_number')}>
              <input {...text('member_number')} placeholder='&nbsp;' required/>
              <span className='label'>Mitgliedsnummer</span>
              <span className='focus-bg'/>
            </Input>
            <Spacer height={20}/>
            <Input {...label('first_name')}>
              <input {...text('first_name')} placeholder='&nbsp;' required/>
              <span className='label'>Vorname</span>
              <span className='focus-bg'/>
            </Input>
            <Spacer height={20}/>
            <Input {...label('last_name')}>
              <input {...text('last_name')} placeholder='&nbsp;' required/>
              <span className='label'>Nachname</span>
              <span className='focus-bg'/>
            </Input>
          </Box>
          <Box width={['100%', '50%', '50%', '50%']}>
            <Input {...label('email')}>
              <input {...email('email')} placeholder='&nbsp;' required/>
              <span className='label'>E-Mail</span>
              <span className='focus-bg'/>
            </Input>
            <Spacer height={20}/>
            <Input {...label('password')}>
              <input {...password('password')} placeholder='&nbsp;' required/>
              <span className='label'>Passwort</span>
              <span className='focus-bg'/>
            </Input>
            <Spacer height={30}/>
            <Button primary fullWidth type='submit'
                    disabled={formState.values.email === '' || formState.values.password === '' || loading}>
              {loading
                ? <Spinner size={20} color='#fff' />
                : <>Registrieren</>
              }
            </Button>
          </Box>
        </Flex>
      </Form>
      <Spacer/>
      <div style={{ textAlign: 'center' }}>
        <p>Bereits registriert? <br/> <Link to='/mitgliederbereich'>Zur Anmeldung</Link></p>
      </div>
    </div>
  )
};

export default Register;
