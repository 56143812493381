import React from 'react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import * as S from './styles.js';
import Error  from '../Error/Error';

const Products = ({ product }) => {

  let params;

  if (!product) {
    params = {
      'filter[recommendation]': 1,
      fields: '*.*'
    }
  } else {
    params = {
      'filter[recommendation]': 0,
      fields: '*.*'
    }
  }

  const { data, loading, error } = useClient('product', params, null, true);

  return (
    <div>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {error && <Error message='Keine Produkte gefunden' />}
      {data &&
      <S.List>
        {data.map(product => {
          return (
            <li key={product.id}>
              <div>
                <img src={product.image.data.full_url} alt={product.title}/>
              </div>
              <div>
                <h3>{product.title}</h3>
                {product.price_in_euro &&
                  <p>{product.price_in_euro === '0' ? 'Kostenfrei' : `${product.price_in_euro} €`}</p>
                }
                {product.description && <p>{product.description}</p>}
              </div>
            </li>
          )
        })}
      </S.List>
      }
    </div>
  )
};

export default Products;
